import * as React from "react";
import { InputboxProperties } from "./inputbox.properties";
import classNames from "classnames";
import styles from "./inputbox.module.scss";

export class Inputbox extends React.PureComponent<InputboxProperties, {
    value?: string;
}> {
    private lastValue?: string;
    private inputReference = React.createRef<HTMLInputElement>();

    public constructor(props: InputboxProperties) {
        super(props);
        this.state = {
            value: props.value,
        };

        this.lastValue = props.value;
    }

    public componentDidUpdate(oldProps: InputboxProperties): void {
        if (
            oldProps.value !== this.props.value ||
            oldProps.isDisabled !== this.props.isDisabled
        ) {
            this.setState(
                {
                    value: this.props.value,
                }
            );

            this.lastValue = this.props.value;
        }
    }

    public render(): React.ReactNode {
        const { isDisabled, className, style = "form", type = "text" } = this.props;

        return <input
            className={
                classNames(
                    className,
                    styles.inputbox,
                    {
                        [styles.form]: style === "form",
                    }
                )
            }
            disabled={isDisabled}
            type={type}
            onChange={(e) => this.onTextChange(e.target.value)}
            onBlur={(e) => this.onTextChanged(e.target.value)}
            onKeyDown={this.onKeyDown}
            value={this.state.value ?? ""}
            // min={(type === "number") ? min : undefined}
            // max={(type === "number") ? max : undefined}
            // minLength={min != null && (type === "text") ? parseInt(min?.toString(), 10) : undefined}
            // maxLength={max != null && (type === "text") ? parseInt(max?.toString(), 10) : undefined}
        />;
    }

    public focus(): void {
        setTimeout(
            () => {
                this.inputReference.current?.focus();
                this.inputReference.current?.setSelectionRange(
                    this.inputReference.current.value?.length ?? 0,
                    this.inputReference.current.value?.length ?? 0
                );
            },
            100
        );
    }

    public blur(): void {
        setTimeout(
            () => {
                this.inputReference.current?.blur();
            },
            100
        );
    }

    private onTextChange = (value: string) => {
        if (this.props.isDisabled) {
            return;
        }

        if (this.props.onChange) {
            if (this.props.onChange(value) === false) {
                return;
            }
        }

        this.setState({ value });
    };

    private onTextChanged = (value: string | undefined) => {
        if (this.props.isDisabled) {
            return;
        }

        if (this.props.onChange && value != null) {
            if (this.props.onChange(value) === false) {
                return;
            }
        }

        if (this.props.onChanged && value != null) {
            if (this.props.onChanged(value) === false) {
                value = this.lastValue;
            }
        }

        this.lastValue = value;
        this.setState({ value });
    };

    private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.isDisabled || e.key !== "Enter") {
            return;
        }

        let value: string | undefined = (e.target as HTMLInputElement).value;

        if (this.props.onChanged && value != null) {
            if (this.props.onChanged(value) === false) {
                value = this.lastValue;
            }
        }

        if (this.props.onEnter) {
            if (this.props.onEnter(value ?? "") === false) {
                value = this.lastValue;
            }
        }

        this.lastValue = value;
        this.setState({ value });
    };
}
