import { GameSoundDefinition } from "../../components/game/game.properties";
import { Howl, Howler } from "howler";
import { PreloadHelper } from "./preload.helper";
import gsap from "gsap";

export interface Sound {
    howl?: Howl;
    delay: number;
    isLoop: boolean;
    isStoppable: boolean;
    fadeInDuration: number;
    fadeOutDuration: number;
}

export interface Audio {
    sounds: Sound[];
}

export abstract class AudioHelper {
    private static muted = false;
    private static soundCache = new Map<string, Howl>();
    private static unlockCallbacks = new Map<string, () => void>();
    private static isiOS = (
        () => {
            if (typeof navigator === "undefined") {
                return false;
            }

            const isiOSQuirkPresent = () => {
                const audio = new Audio();

                audio.volume = 0.5;
                return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
            };

            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
            const isAppleDevice = navigator.userAgent.includes("Macintosh");
            const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)
            return isIOS || (isAppleDevice && (isTouchScreen || isiOSQuirkPresent()));
        }
    )();

    private static isSafari = AudioHelper.isiOS || (
        typeof navigator !== "undefined" &&
        /apple/i.test(navigator.vendor) &&
        !/crios/i.test(navigator.userAgent) &&
        !/fxios/i.test(navigator.userAgent) &&
        !/Opera|OPT\//i.test(navigator.userAgent)
    );

    private static isUnlocked = false;

    public static getAudioList(sounds?: GameSoundDefinition | GameSoundDefinition[]): Audio[] {
        if (!sounds) {
            return [];
        }

        if (!Array.isArray(sounds)) {
            sounds = [sounds];
        }

        return sounds.map(
            (definition): Audio => {
                let urls = definition.url;
                if (!Array.isArray(urls)) {
                    urls = [urls];
                }

                return {
                    sounds: urls.map(
                        (url) => {
                            const sound: Sound = {
                                howl: undefined,
                                delay: definition.delay ?? 0,
                                isLoop: definition.loop ?? false,
                                isStoppable: definition.stoppable ?? true,
                                fadeInDuration: definition.fadeIn ?? 0,
                                fadeOutDuration: definition.fadeOut ?? 0,
                            };

                            if (!url) {
                                return sound;
                            }

                            if (!url.toLowerCase().endsWith(".mp3") && !url.toLowerCase().endsWith(".ogg")) {
                                url = AudioHelper.isSafari ? `${url}.mp3` : `${url}.ogg`;
                            }

                            PreloadHelper.preload(
                                url,
                                (asset) => {
                                    if (asset.local) {
                                        if (AudioHelper.isUnlocked) {
                                            sound.howl = this.soundCache.get(asset.local);
                                            if (!sound.howl) {
                                                sound.howl = new Howl(
                                                    {
                                                        src: asset.local,
                                                        volume: 1,
                                                        loop: sound.isLoop,
                                                        html5: AudioHelper.isSafari ? true : undefined,
                                                        //html5: false,
                                                        format: [url.toLowerCase().endsWith(".mp3") ? "mp3" : "ogg"],
                                                    }
                                                );
                                                this.soundCache.set(asset.local, sound.howl);
                                            }
                                        } else {
                                            const key = asset.local + Math.round(Math.random() * 100000).toFixed(0);
                                            AudioHelper.unlockCallbacks.set(
                                                key,
                                                () => {
                                                    AudioHelper.unlockCallbacks.delete(key);
                                                    sound.howl = this.soundCache.get(asset.local!);
                                                    if (!sound.howl) {
                                                        sound.howl = new Howl(
                                                            {
                                                                src: asset.local!,
                                                                volume: 1,
                                                                loop: sound.isLoop,
                                                                // html5: AudioHelper.isSafari ? true : undefined,
                                                                html5: AudioHelper.isSafari ? true : undefined,
                                                                format: [url.toLowerCase().endsWith(".mp3") ? "mp3" : "ogg"],
                                                            }
                                                        );
                                                        this.soundCache.set(asset.local!, sound.howl);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            );

                            return sound;
                        }
                    ),
                };
            }
        );
    }

    public static playAudio(audios?: Audio[], index?: number): void {
        if (!audios?.length) {
            return;
        }

        for (const audio of audios) {
            if (!audio.sounds?.length) {
                continue;
            }

            const audioIndex = Math.min(Math.trunc(index ?? (Math.random() * audio.sounds.length)), audio.sounds.length - 1);
            const sound = audio.sounds[audioIndex];
            if (!sound.howl) {
                continue;
            }

            const play = () => {
                if (!sound.howl) {
                    return;
                }

                if (!!sound.fadeInDuration && !AudioHelper.isiOS) {
                    const id = sound.howl.play();
                    sound.howl.volume(0, id);

                    const object = { x: 0 };
                    gsap.fromTo(
                        object,
                        {
                            x: 0,
                        },
                        {
                            x: 1,
                            duration: sound.fadeInDuration,
                            onUpdate: () => {
                                sound.howl!.volume(object.x, id);
                            },
                            onComplete: () => {
                                sound.howl!.volume(1, id);
                            },
                        }
                    );
                } else {
                    const id = sound.howl.play();
                    if (!AudioHelper.isiOS) {
                        sound.howl.volume(1, id);
                    }
                }
            };

            if (!audio.sounds[audioIndex].delay) {
                play();
            } else {
                setTimeout(play, audio.sounds[audioIndex].delay * 1000);
            }
        }
    }

    public static stopAudio(audios?: Audio[]): void {
        if (!audios?.length) {
            return;
        }

        for (const audio of audios) {
            if (!audio.sounds?.length) {
                continue;
            }

            for (const sound of audio.sounds) {
                if (!sound.howl?.playing() || !sound.isStoppable) {
                    continue;
                }

                if (!!sound.fadeOutDuration && !AudioHelper.isiOS) {
                    const object = { x: 0 };
                    gsap.fromTo(
                        object,
                        {
                            x: 1,
                        },
                        {
                            x: 0,
                            duration: sound.fadeOutDuration,
                            onUpdate: () => {
                                sound.howl!.volume(object.x);
                            },
                            onComplete: () => {
                                sound.howl!.stop();
                                sound.howl!.seek(0);
                                sound.howl!.volume(0);
                            },
                        }
                    );
                } else {
                    sound.howl.stop();
                    sound.howl.seek(0);
                    if (!AudioHelper.isiOS) {
                        sound.howl.volume(0);
                    }
                }
            }
        }
    }

    public static isMuted(): boolean {
        return AudioHelper.muted;
    }

    public static mute(): void {
        AudioHelper.muted = true;
        if (!AudioHelper.isiOS) {
            Howler.volume(0);
        }
        Howler.mute(true);
    }

    public static unmute(): void {
        AudioHelper.muted = false;
        if (!AudioHelper.isiOS) {
            Howler.volume(1);
        }
        Howler.mute(false);
    }

    public static initialize(): void {
        Howler.html5PoolSize = 20;
        Howler.autoUnlock = false;
        Howler.autoSuspend = false;
    }

    public static unlock(): void {
        AudioHelper.isUnlocked = true;

        for (const callback of AudioHelper.unlockCallbacks.values()) {
            callback();
        }
        AudioHelper.unlockCallbacks.clear();
    }
}
