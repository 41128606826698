import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import { GameButtonLayer } from "../button/game-button.layer";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameImageDefinition } from "../../game.properties";
import { GameImageLayerProps } from "./game-image-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import { Spine } from "@pixi-spine/all-4.1";

export class GameImageLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    wrapper: PIXI.Container;
},
string,
GameImageLayerProps,
PIXI.Container
> {
    private sound: Audio[] = [];
    private sprite: PIXI.AnimatedSprite | PIXI.Sprite | Spine | undefined;
    private debugGraphic2?: PIXI.Graphics;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly definition: GameImageDefinition
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: definition.autoplay ? "playing" : "static",
                startIndex: 0,
            },
            {
                wrapper: new PIXI.Container(),
            },
            {
                [`Image_${definition.url}`]: definition.url,
            }
        );

        this.shapes.wrapper.alpha = definition.alpha ?? 1;

        if (this.definition.clickAnimation) {
            this.shapes.wrapper.interactive = true;
            this.shapes.wrapper.on("pointertap", this.onClick);
            this.sound = AudioHelper.getAudioList(definition.clickAudio);
        }
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        if (this.sprite) {
            this.sprite.scale.x = 1;
            this.sprite.scale.y = 1;

            const spriteWidth = this.definition.width || (this.sprite instanceof Spine ? this.sprite.spineData.width : this.sprite.texture.width);
            const spriteHeight = this.definition.height || (this.sprite instanceof Spine ? this.sprite.spineData.height : this.sprite.texture.height);
            if (this.sprite instanceof Spine) {
                this.sprite.x = spriteWidth / 2;
                this.sprite.y = spriteHeight / 2;
            } else {
                this.sprite.x = 0;
                this.sprite.y = 0;
            }

            const box = this.calculateLayout(
                width,
                height,
                {
                    ...this.definition,
                    width: spriteWidth,
                    height: spriteHeight,
                }
            );

            this.shapes.wrapper.scale.x = box.scale;
            this.shapes.wrapper.scale.y = box.scale;
            this.shapes.wrapper.x = box.x;
            this.shapes.wrapper.y = box.y;
        }

        if (this.definition.hitArea) {
            this.shapes.wrapper.hitArea = GameButtonLayer.getHitPointFromNormalized(this.definition.hitArea, 1);
        }

        if (this.debugGraphic2) {
            this.debug();
        }
    }

    public debug(): void {
        super.debug();
        if (!this.debugGraphic2) {
            this.debugGraphic2 = new PIXI.Graphics();
            this.container.addChildAt(this.debugGraphic2, 0);
        }

        this.debugGraphic2.clear();
        this.debugGraphic2.beginFill(0x0000ff);
        this.debugGraphic2.drawRect(this.shapes.wrapper.x, this.shapes.wrapper.y, this.shapes.wrapper.width, this.shapes.wrapper.height);
        this.debugGraphic2.endFill();
    }

    protected stateChanged(): void {
        const asset = this.assets[`Image_${this.definition.url}`];
        if (asset.videoControl) {
            if (this.props.state === "playing") {
                void asset.videoControl.play().catch(() => undefined);
            } else {
                asset.videoControl.pause();
                asset.videoControl.currentTime = 0;
            }
        } else if (this.sprite instanceof PIXI.AnimatedSprite) {
            const index = this.props.startIndex ?? Math.floor(Math.random() * (asset.textures?.length ?? 0));
            if (this.props.state === "playing") {
                this.sprite.gotoAndPlay(index);
            } else {
                this.sprite.gotoAndStop(index);
            }
        } else if (this.sprite instanceof Spine) {
            if (this.props.state === "playing") {
                if (
                    this.sprite.state.hasAnimation(this.definition.animation ?? "animation")
                ) {
                    this.sprite.state.setAnimation(1, this.definition.animation ?? "animation", this.definition.loop ?? true);
                }
            } else {
                this.sprite.state.clearTrack(1);
                this.sprite.skeleton.setToSetupPose();
            }
        }
    }

    protected loaded(): void {
        super.loaded();

        const asset = this.assets[`Image_${this.definition.url}`];
        if (asset.videoControl) {
            document.addEventListener(
                "click",
                () => {
                    this.update();
                },
                { once: true }
            );
        } else {
            this.update();
        }
    }

    private update() {
        const asset = this.assets[`Image_${this.definition.url}`];
        if (!this.sprite) {
            if (!!asset.spine) {
                this.sprite = new Spine(asset.spine);
                this.sprite.autoUpdate = true;
                if (this.definition.skin) {
                    this.sprite.skeleton.setSkinByName(this.definition.skin);
                }

                this.sprite.state.timeScale = this.definition.speed ?? 1;
                if (this.definition.autoplay) {
                    if (this.sprite.state.hasAnimation(this.definition.animation ?? "animation")) {
                        this.sprite.state.setAnimation(0, this.definition.animation ?? "animation", this.definition.loop ?? true);
                    }
                }
            } else if (!!asset?.textures?.length && asset?.textures.length > 1) {
                const speed = this.definition.speed ?? 0.5;
                const emptyTexturesBeginning = Math.round((this.definition.startDelay ?? 0) * (60 * speed) / 1000);
                const emptyTexturesEnding = Math.round((this.definition.endDelay ?? 0) * (60 * speed) / 1000);
                this.sprite = new PIXI.AnimatedSprite(
                    [
                        ...([ ...new Array<unknown>(emptyTexturesBeginning) ].map(() => asset.textures![0] ?? PIXI.Texture.EMPTY)),
                        ...asset.textures,
                        ...([ ...new Array<unknown>(emptyTexturesEnding) ].map(() => asset.textures![asset.textures!.length - 1] ?? PIXI.Texture.EMPTY)),
                    ]
                );

                this.sprite.blendMode = this.definition.blend ?? PIXI.BLEND_MODES.NORMAL;
                (this.sprite as PIXI.AnimatedSprite).animationSpeed = speed;
                (this.sprite as PIXI.AnimatedSprite).loop = this.definition.loop ?? true;
            } else if (!!asset?.textures?.length && !!asset.textures[0].baseTexture) {
                this.sprite = new PIXI.Sprite(asset.textures[0]);
                this.sprite.blendMode = this.definition.blend ?? PIXI.BLEND_MODES.NORMAL;
            }

            if (!!this.sprite) {
                this.shapes.wrapper.addChild(this.sprite);
            }
        }

        this.resize(
            this.width || this.definition.width || 0,
            this.height || this.definition.height || 0
        );

        this.stateChanged();
    }

    private onClick = () => {
        if (
            !this.definition.clickAnimation ||
            !(this.sprite instanceof Spine) ||
            !this.sprite.state.hasAnimation(this.definition.clickAnimation) ||
            this.sprite.state.tracks[0]?.isComplete() === false
        ) {
            return;
        }

        this.sprite.state.setAnimation(0, this.definition.clickAnimation, false);
        AudioHelper.playAudio(this.sound);
        this.stateChanged();
    };
}
