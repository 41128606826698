import * as React from "react";
import { GlobalPokerLoaderProperties } from "./global-poker-loader.properties";
import { Translate } from "../translate/translate";
import brand from "./assets/brand.png";
import classNames from "classnames";
import styles from "./global-poker-loader.module.scss";

export class GlobalPokerLoader extends React.PureComponent<GlobalPokerLoaderProperties, {
    isReady: boolean;
    isExiting: boolean;
}> {
    private isComponentMounted = false;

    public constructor(props: GlobalPokerLoaderProperties) {
        super(props);
        this.state = {
            isReady: false,
            isExiting: false,
        };
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;

        setTimeout(
            () => {
                if (!this.isComponentMounted) {
                    return;
                }

                this.setState(
                    {
                        isReady: true,
                    }
                );
            },
            5000
        );
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;
    }

    public render(): React.ReactNode {
        return <section
            className={
                classNames(
                    styles.loader,
                    {
                        [styles.in]: this.props.isLoading,
                        [styles.out]: !this.props.isLoading,
                        [styles.mobile]: this.props.isMobile,
                    }
                )
            }
            onClick={this.onClick}
        >
            <span>
                <span>
                    <img src={brand} />
                </span>
            </span>
            <div
                className={
                    classNames(
                        {
                            [styles.out]: this.props.isLoaded,
                        }
                    )
                }
                style={{ ["--progress" as any]: `${this.props.percentage.toFixed(2)}%` }}
            />
            <h3
                className={
                    classNames(
                        {
                            [styles.in]: this.props.isLoaded && this.state.isReady,
                            [styles.out]: this.props.isLoaded && this.state.isReady && this.state.isExiting,
                        }
                    )
                }
            ><Translate>PRESS TO PLAY</Translate></h3>
        </section>;
    }

    private onClick = () => {
        if (!this.props.isLoaded || !this.state.isReady || this.state.isExiting) {
            return;
        }

        this.setState(
            {
                isExiting: true,
            }
        );

        setTimeout(
            () => {
                if (!this.isComponentMounted) {
                    return;
                }

                this.props.onExit();
            },
            500
        );
    };
}
