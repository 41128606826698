import * as PIXI from "pixi.js-legacy";
import { AudioHelper } from "../../common/helpers/audio.helper";
import { CustomEase } from "gsap/all";
import { GameMainLayer } from "./layers/main/game-main.layer";
import { GameProperties } from "./game.properties";
import { SlotsActionDto } from "../../services/slots/dtos/slots-action.dto";
import gsap from "gsap";
import React, { createRef, PureComponent } from "react";

export class Game<TBoards extends string = string, TOverlays extends string = string> extends PureComponent<GameProperties<TBoards>> {
    private reference = createRef<HTMLDivElement>();
    private game: GameMainLayer | undefined;
    private application: PIXI.Application | undefined;
    private resizeObserver: ResizeObserver | undefined;

    public constructor(props: GameProperties<TBoards>) {
        super(props);
    }

    public componentDidMount(): void {
        if (!this.reference.current || typeof window === "undefined") {
            return;
        }

        // initialize PIXI
        PIXI.settings.RESOLUTION = this.props.game.density || Math.round((PIXI.utils.isMobile.any ? Math.min(window.devicePixelRatio, 2) : 0) || 1);
        this.application = new PIXI.Application(
            {
                width: this.reference.current.clientWidth,
                height: this.reference.current.clientHeight,
                antialias: this.props.game.antiAliasing ?? false,
                resolution: PIXI.settings.RESOLUTION,
                backgroundColor: 0x000,
                backgroundAlpha: 1,
                autoDensity: true,
                sharedTicker: true,
                sharedLoader: true,
                forceCanvas: false,
                powerPreference: "high-performance",
            }
        );

        (globalThis as Record<string, any>).__PIXI_APP__ = this.application;

        this.application.renderer.view.style.position = "absolute";
        this.application.renderer.view.style.display = "block";
        this.application.renderer.view.style.top = "0";
        this.application.renderer.view.style.bottom = "0";
        this.application.renderer.view.style.right = "0";
        this.application.renderer.view.style.left = "0";
        this.application.renderer.view.style.width = "100%";
        this.application.renderer.view.style.height = "100%";
        this.reference.current.appendChild(this.application.view);

        const pixiInspectWindow = window as unknown as Record<string, { register: (arg: unknown) => void }>;
        // eslint-disable-next-line no-underscore-dangle
        if (typeof pixiInspectWindow.__PIXI_INSPECTOR_GLOBAL_HOOK__ !== "undefined") {
            // eslint-disable-next-line no-underscore-dangle
            pixiInspectWindow.__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI });
        }

        // initialize game
        this.game = new GameMainLayer(
            this.props.configuration,
            this.application,
            this.props.game,
            this.props.defaultBoard
        );

        // subscribe to resizes
        const resizeCallback = () => {
            const width = this.reference.current?.clientWidth;
            const height = this.reference.current?.clientHeight;
            if (!width || !height) {
                return;
            }

            if (this.application) {
                this.application?.renderer.resize(width, height);
                this.game?.resize(width, height);
            }
        };

        this.resizeObserver = new ResizeObserver(resizeCallback);
        this.resizeObserver.observe(this.reference.current);

        // replace GSAP ticker with PIXI's shared ticker
        gsap.ticker.remove(gsap.updateRoot);
        PIXI.Ticker.shared.add(this.onPIXITick);

        // register custom ease plugin
        gsap.registerPlugin(CustomEase);

        // graph is created, lets load the game
        PIXI.Loader.shared.load();

        // configure howler
        AudioHelper.initialize();
    }

    public componentDidUpdate(oldProps: GameProperties): void {
        if (oldProps.configuration !== this.props.configuration) {
            this.game?.config(this.props.configuration);
        }

        if (oldProps.isReady !== this.props.isReady) {
            if (!this.props.isReady) {
                this.game?.busy();
            } else {
                this.game?.ready();
            }
        }
    }

    public componentWillUnmount(): void {
        PIXI.Ticker.shared.remove(this.onPIXITick);
        gsap.ticker.add(gsap.updateRoot);
        this.resizeObserver?.disconnect();
        this.application?.destroy(true, true);
    }

    public async initiate(): Promise<void> {
        await this.game?.initiate();
    }

    public async apply(actions: SlotsActionDto[]): Promise<void> {
        for (const action of actions) {
            await this.game?.apply(action);
        }
    }

    public reset(): void {
        this.game?.ready();
    }

    public busy(): void {
        this.game?.busy();
    }

    public skip(): void {
        this.game?.skip();
    }

    public enter(): Promise<void> {
        return this.game?.enter() ?? Promise.resolve();
    }

    public showOverlay(name: TOverlays): Promise<void> {
        return this.game?.showOverlay(name) ?? Promise.resolve();
    }

    public hideOverlay(name: TOverlays): Promise<void> {
        return this.game?.hideOverlay(name) ?? Promise.resolve();
    }

    public render(): React.ReactNode {
        return <div
            style={{ position: "relative", width: "100%", height: "100%" }}
            ref={this.reference}
        />;
    }

    private onPIXITick = () => {
        gsap.updateRoot(Date.now() / 1000);
    };
}
