import * as React from "react";
import { Audio, AudioHelper } from "../../common/helpers/audio.helper";
import { ButtonProperties } from "./button.properties";
import ButtonClickAudio from "./assets/button2.mp3";
import classNames from "classnames";
import CoinsClickAudio from "./assets/coins.mp3";
import CoinsMaxClickAudio from "./assets/coins_max.mp3";
import styles from "./button.module.scss";

export class Button extends React.PureComponent<ButtonProperties, {
    isBusy: boolean;
}> {
    private static sound: Map<ButtonProperties["sound"], Audio[]> | undefined;
    public constructor(props: ButtonProperties) {
        super(props);
        this.state = {
            isBusy: false,
        };
    }

    public componentDidMount(): void {
        if (!Button.sound) {
            Button.sound = new Map(
                [
                    [
                        "button",
                        AudioHelper.getAudioList(
                            {
                                url: ButtonClickAudio,
                            }
                        ),
                    ],
                    [
                        "coins",
                        AudioHelper.getAudioList(
                            {
                                url: CoinsClickAudio,
                            }
                        ),
                    ],
                    [
                        "coins-max",
                        AudioHelper.getAudioList(
                            {
                                url: CoinsMaxClickAudio,
                            }
                        ),
                    ],
                ]
            );
        }
    }

    public render(): React.ReactNode {
        const { isDisabled, children, className, style = "normal", isActive } = this.props;
        const { isBusy } = this.state;

        return <button
            className={
                classNames(
                    className,
                    styles.button,
                    {
                        [styles.normal]: style === "normal",
                        [styles.form]: style === "form",
                        [styles.icon]: style === "icon",
                        [styles.game]: style === "game",
                        [styles.toggle]: isActive != null,
                        [styles.active]: !!isActive,
                    }
                )
            }
            onClick={isDisabled ? undefined : this.onClick}
            disabled={isDisabled || isBusy}
        >
            <span>{children}</span>
        </button>;
    }

    private onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        // e.preventDefault();
        // e.stopPropagation();

        if (!!this.props.isDisabled || !!this.state.isBusy || !this.props.onClick) {
            return false;
        }

        this.setState(
            {
                isBusy: true,
            }
        );

        try {
            AudioHelper.playAudio(Button.sound?.get(this.props.sound ?? "button"));
            await this.props.onClick();
        } catch (error) {
            // ignore
        }

        this.setState(
            {
                isBusy: false,
            }
        );
    };
}
