import * as PIXI from "pixi.js";
import {
    GameBoardDefinition,
    GameDefinition,
    GameFormDefinition,
    GameFormElementBaseDefinition,
    GameFormElementType,
    GameImageDefinition,
    GameOverlayDefinition,
    GamePaylineDefinition,
    GameReelsDefinition,
    GameSound,
    GameSymbolDefinition,
    GameTransitionDefinition
} from "../game/game.properties";
import { GuideSlideDefinition } from "../guide-modal/guide-modal.properties";
import { HomeSlideDefinition } from "../home/home.properties";
import languages from "./rattle-snake-game.languages.json";

export type RattleSnakeGameDefinition = GameDefinition<RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay, GameSound, RattleSnakeGameNumberOfReels>;

export interface RattleSnakeGameConfiguration<T extends GameDefinition> {
    homePage?: {
        background?: string;
        desktopSlides: HomeSlideDefinition[];
        mobileSlides: HomeSlideDefinition[];
    };
    guide: {
        desktopSlides: GuideSlideDefinition[];
        mobileSlides: GuideSlideDefinition[];
    };
    desktopGame: T;
    mobileGame?: T;
    paylines: number;
}

const translate = (text: string, language: string) => {
    const langs = languages as Record<string, Record<string, string | undefined> | undefined>;
    if (!!language && typeof langs[language] === "object") {
        text = langs[language]![text] || text;
    }

    return text;
};

// --------------- game config
// the name of modals
export type RattleSnakeGameOverlay = "win" | "big-win" | "pickem" | "free-spin-enter" | "free-spin-stats" | "free-spin-exit";

// the name of game boards
export type RattleSnakeGameBoard = "main" | "bonus";

// the name of reel symbols
export type RattleSnakeGameSymbol = "B" | "F" | "H1" | "H2" | "H3" | "H4" | "N1" | "N2" | "N3" | "N4" | "N5" | "W";

// the number of reels of each board
export type RattleSnakeGameNumberOfReels = 5;

const SymbolDesktopMainDefinitions: Record<RattleSnakeGameSymbol, GameSymbolDefinition> = {
    B: {
        url: "/assets/symbols/RS_symbols_antivenom.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_bonus_land_v1",
                "/assets/sounds/RS_bonus_land_v2",
                "/assets/sounds/RS_bonus_land_v3",
                "/assets/sounds/RS_bonus_land_v4",
                "/assets/sounds/RS_bonus_land_v5",
            ],
        },
    },
    F: {
        url: "/assets/symbols/RS_symbols_rattlesnake_freespins-2p.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_scatter_land_v1",
                "/assets/sounds/RS_scatter_land_v2",
                "/assets/sounds/RS_scatter_land_v3",
                "/assets/sounds/RS_scatter_land_v4",
                "/assets/sounds/RS_scatter_land_v5",
            ],
        },
    },
    H1: {
        url: "/assets/symbols/RS_Symbols_Safe_chips.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
    },
    H2: {
        url: "/assets/symbols/RS_symbols_goldsnake.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H3: {
        url: "/assets/symbols/RS_symbols_goldtail.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H4: {
        url: "/assets/symbols/RS_symbols_dynamite.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N1: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "gp",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N2: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "a",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N3: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "k",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N4: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "q",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N5: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "j",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    W: {
        url: "/assets/symbols/RS_symbols_wild.json",
        focusAnimations: "main",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
        sound: {
            url: "/assets/sounds/RS_wild",
        },
    },
};

const SymbolMobileMainDefinitions: Record<RattleSnakeGameSymbol, GameSymbolDefinition> = {
    B: {
        url: "/assets/symbols/mobile/RS_symbols_antivenom.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_bonus_land_v1",
                "/assets/sounds/RS_bonus_land_v2",
                "/assets/sounds/RS_bonus_land_v3",
                "/assets/sounds/RS_bonus_land_v4",
                "/assets/sounds/RS_bonus_land_v5",
            ],
        },
    },
    F: {
        url: "/assets/symbols/mobile/RS_symbols_rattlesnake_freespins-2p.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_scatter_land_v1",
                "/assets/sounds/RS_scatter_land_v2",
                "/assets/sounds/RS_scatter_land_v3",
                "/assets/sounds/RS_scatter_land_v4",
                "/assets/sounds/RS_scatter_land_v5",
            ],
        },
    },
    H1: {
        url: "/assets/symbols/mobile/RS_Symbols_Safe_chips.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
    },
    H2: {
        url: "/assets/symbols/mobile/RS_symbols_goldsnake.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H3: {
        url: "/assets/symbols/mobile/RS_symbols_goldtail.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H4: {
        url: "/assets/symbols/mobile/RS_symbols_dynamite.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N1: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "gp",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N2: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "a",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N3: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "k",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N4: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "q",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N5: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "j",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    W: {
        url: "/assets/symbols/mobile/RS_symbols_wild.json",
        focusAnimations: "main",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
        sound: {
            url: "/assets/sounds/RS_wild",
        },
    },
};

const SymbolDesktopFreeSpinDefinitions: Record<RattleSnakeGameSymbol, GameSymbolDefinition> = {
    B: {
        url: "/assets/symbols/RS_symbols_antivenom_fram.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_bonus_land_v1",
                "/assets/sounds/RS_bonus_land_v2",
                "/assets/sounds/RS_bonus_land_v3",
                "/assets/sounds/RS_bonus_land_v4",
                "/assets/sounds/RS_bonus_land_v5",
            ],
        },
    },
    F: {
        url: "/assets/symbols/RS_symbols_rattlesnake_freespins-2p.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_scatter_land_v1",
                "/assets/sounds/RS_scatter_land_v2",
                "/assets/sounds/RS_scatter_land_v3",
                "/assets/sounds/RS_scatter_land_v4",
                "/assets/sounds/RS_scatter_land_v5",
            ],
        },
    },
    H1: {
        url: "/assets/symbols/RS_Symbols_Safe_chips.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
    },
    H2: {
        url: "/assets/symbols/RS_symbols_goldsnake.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H3: {
        url: "/assets/symbols/RS_symbols_goldtail.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H4: {
        url: "/assets/symbols/RS_symbols_dynamite.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N1: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "gp",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N2: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "a",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N3: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "k",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N4: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "q",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N5: {
        url: "/assets/symbols/RS_symbols_coins.json",
        skin: "j",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    W: {
        url: "/assets/symbols/RS_symbols_wild.json",
        focusAnimations: "main",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
        sound: {
            url: "/assets/sounds/RS_wild",
        },
    },
};

const SymbolMobileFreeSpinDefinitions: Record<RattleSnakeGameSymbol, GameSymbolDefinition> = {
    B: {
        url: "/assets/symbols/mobile/RS_symbols_antivenom_fram.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_bonus_land_v1",
                "/assets/sounds/RS_bonus_land_v2",
                "/assets/sounds/RS_bonus_land_v3",
                "/assets/sounds/RS_bonus_land_v4",
                "/assets/sounds/RS_bonus_land_v5",
            ],
        },
    },
    F: {
        url: "/assets/symbols/mobile/RS_symbols_rattlesnake_freespins-2p.json",
        focusAnimations: [
            "00_main",
            {
                name: "01_secondary",
                loop: true,
            },
        ],
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/RS_scatter_land_v1",
                "/assets/sounds/RS_scatter_land_v2",
                "/assets/sounds/RS_scatter_land_v3",
                "/assets/sounds/RS_scatter_land_v4",
                "/assets/sounds/RS_scatter_land_v5",
            ],
        },
    },
    H1: {
        url: "/assets/symbols/mobile/RS_Symbols_Safe_chips.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
    },
    H2: {
        url: "/assets/symbols/mobile/RS_symbols_goldsnake.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H3: {
        url: "/assets/symbols/mobile/RS_symbols_goldtail.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    H4: {
        url: "/assets/symbols/mobile/RS_symbols_dynamite.json",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N1: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "gp",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N2: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "a",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N3: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "k",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N4: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "q",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    N5: {
        url: "/assets/symbols/mobile/RS_symbols_coins.json",
        skin: "j",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 1,
    },
    W: {
        url: "/assets/symbols/mobile/RS_symbols_wild.json",
        focusAnimations: "main",
        activeAnimations: {
            name: "main",
            loop: true,
        },
        idleAnimations: "idle",
        heightFactor: 2,
        sound: {
            url: "/assets/sounds/RS_wild",
        },
    },
};

export const RattleSnakeGameMainReelSymbols: GameReelsDefinition<RattleSnakeGameSymbol, RattleSnakeGameNumberOfReels>["symbols"] = {
    reel0: [
        "N5", "N4", "N3", "H4", "N4", "H3", "H4", "N5", "N2", "N4", "H3", "N1", "N5", "N4", "H4", "N3", "H3", "N1", "N2", "B", "N1", "H1", "H1", "N2", "N5", "N2", "N4",
        "H1", "H1", "N3", "N2", "H2", "N1", "N5", "N3", "N4", "N5", "H3", "N3", "N4", "F", "N3", "H1", "H1", "N1", "N2", "H4", "N5", "N4", "H3", "N3", "H4", "H3", "N2",
        "H4", "N1", "H3", "H4", "N3", "N5", "B", "N1", "H1", "H1", "N2", "H3", "H4",
    ],
    reel1: [
        "N3", "H1", "H1", "N4", "N3", "H4", "N5", "N3", "N2", "W", "W", "N1", "N5", "N4", "H3", "H2", "N3", "N1", "H2", "N1", "B", "N2", "W", "W", "N5", "H2", "N4", "N3",
        "H2", "H4", "N5", "H1", "H1", "N5", "N4", "H2", "H4", "N4", "H3", "N5", "F", "N2", "H1", "H1", "N1", "N2", "H3", "N5", "N4", "H2", "N2", "N1", "N3", "H3", "N2",
        "H2", "H3", "N1", "N3", "N4", "B", "N1", "W", "W", "N2", "H2", "H3", "N4",
    ],
    reel2: [
        "H2", "N1", "N4", "N2", "H3", "H2", "H4", "N5", "N3", "H4", "H2", "N2", "H2", "H4", "N1", "B", "N4", "W", "W", "N5", "N4", "N2", "N5", "N1", "N4", "H2", "N1", "N4",
        "H2", "N2", "F", "N5", "H1", "H1", "N5", "N3", "H3", "N5", "N3", "H4", "H2", "N1", "H4", "N4", "N3", "B", "N1", "W", "W", "N5", "N2", "H4", "N3", "H2", "N1", "N4",
        "H2", "H4", "N2", "F", "N3", "H1", "H1", "N2", "N5", "H2", "H4", "N3",
    ],
    reel3: [
        "N1", "H3", "H4", "H2", "N3", "N5", "H2", "H3", "H4", "N1", "F", "N5", "H1", "H1", "N3", "N4", "H2", "H3", "N2", "B", "N4", "W", "W", "N3", "N1", "N4", "H2", "H4",
        "N3", "F", "N2", "H1", "H1", "N1", "N2", "N5", "H3", "H2", "N4", "B", "N5", "W", "W", "N4", "N1", "H2", "N4", "N5", "N3", "F", "N3", "H1", "H1", "N3", "N2", "N5",
        "H4", "H2", "N3", "N2", "N4", "H3", "N5", "N2", "H4", "N4", "N1", "H3", "H4",
    ],
    reel4: [
        "H2", "H3", "N3", "N4", "H4", "H2", "H3", "N5", "H2", "N4", "N5", "F", "N1", "H1", "H1", "N4", "H3", "H4", "N5", "N3", "B", "N2", "W", "W", "N2", "N1", "H4", "H3",
        "N4", "N1", "F", "N5", "H1", "H1", "N3", "H4", "H2", "N5", "N4", "N3", "B", "N4", "W", "W", "N1", "H3", "H4", "H2", "H3", "N1", "N2", "N3", "F", "N2", "H1", "H1",
        "H4", "N5", "N1", "N4", "B", "N5", "W", "W", "N3", "N2", "H4", "H2", "N2", "N3",
    ],
};

export const RattleSnakeGameBonusReelSymbols: GameReelsDefinition<RattleSnakeGameSymbol, RattleSnakeGameNumberOfReels>["symbols"] = {
    reel0: [
        "N4", "H4", "H3", "N3", "H1", "H1", "N2", "H3", "H2", "N5", "N4", "H4", "H2", "N2", "N1", "H1", "H1", "N1", "N2", "H2", "H4", "N3", "N5", "H1", "H1", "N2", "H3",
        "H4", "H2", "N1", "H3", "H2", "H4", "N4", "H1", "H1", "N3", "N4", "H3", "H4", "N3", "N5", "H1", "H1", "N1", "N5", "H1", "H1",
    ],
    reel1: [
        "N5", "H1", "H1", "N4", "H3", "H4", "N1", "H1", "H1", "N2", "H4", "H2", "N3", "N4", "H3", "H2", "N2", "N1", "H1", "H1", "N1", "N3", "W", "W", "N3", "N5", "H1",
        "H1", "N2", "H4", "H3", "H2", "N1", "H4", "H2", "H3", "N4", "W", "W", "N5", "N2", "H1", "H1", "N3", "N5", "H1", "H1", "N4",
    ],
    reel2: [
        "H2", "H3", "N4", "H4", "H3", "N3", "N5", "H1", "H1", "N1", "H2", "N3", "N2", "W", "W", "N3", "N5", "W", "W", "N4", "H4", "N2", "H1", "H1", "N1", "H3", "N4",
        "N2", "W", "W", "N2", "N5", "H1", "H1", "N5", "H4", "H2", "H3", "N3", "H2", "H4", "N1", "H1", "H1", "N4", "N1", "W", "W",
    ],
    reel3: [
        "H3", "H4", "N1", "H2", "H3", "H4", "H2", "N2", "W", "W", "N2", "N5", "H1", "H1", "N3", "N5", "W", "W", "N2", "H4", "N4", "N3", "W", "W", "N4", "H2", "N1", "N3",
        "W", "W", "N2", "N1", "H1", "H1", "N5", "H3", "H2", "N4", "H1", "H1", "N5", "N4", "W", "W", "N1", "H3", "H4", "N3",
    ],
    reel4: [
        "W", "W", "N2", "N5", "W", "W", "N1", "H4", "N3", "N5", "N1", "B", "N4", "H1", "H1", "N3", "N4", "N2", "H2", "N5", "N4", "N2", "W", "W", "H3", "N1", "B", "N1",
        "N5", "W", "W", "H4", "N3", "W", "W", "N2", "H2", "N4", "N4", "N3", "W", "W", "H3", "H4", "W", "W", "H2", "H3", "N5",
    ],
};

const getGameDustTransition = (audio?: "fs" | "main"): GameTransitionDefinition => (
    {
        elements: {
            dust: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/transitions/dust-0.json",
                    // blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "cover",
                    autoplay: true,
                    loop: false,
                },
            },
        },
        audio: !audio ? undefined : {
            url: `/assets/sounds/RS_intro_${audio}`,
        },
        duration: 2,
        outDelay: 0.5,
        inDelay: 0,
        fullscreen: true,
        width: 1920,
        height: 1080,
        fit: "cover",
    }
);

const getGamePosition = (_device: "desktop" | "mobile"): GameFormElementBaseDefinition & Required<Pick<GameFormElementBaseDefinition, "width" | "height">> => (
    {
        width: 2567,
        height: 1445,
        paddingX: 0,
        paddingY: 0,
        offsetX: 0,
        offsetY: 0,
    }
);

const getWinOverlay = (_device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            // overlay: {
            //     type: GameFormElementType.Image,
            //     appearDelay: 0.3,
            //     payload: {
            //         url: "/assets/overlays/overlay-frame.json",
            //         fit: "contain",
            //     },
            // },
            image: {
                type: GameFormElementType.Image,
                appearDelay: 0.3,
                payload: {
                    url: "/assets/overlays/small-0.json",
                    fit: "contain",
                },
            },
            bonus: {
                type: GameFormElementType.Text,
                appearDelay: 0.3,
                payload: {
                    source: "winnings",
                    preCaption: "{CURRENCY}",
                    fontFamily: "MontrenaRough",
                    decimals: 2,
                    animated: false,
                    fontSize: 76,
                    fill: 0x370000,
                    offsetX: 0,
                    offsetY: -20,
                    paddingX: 950,
                },
            },
            click: {
                type: GameFormElementType.Text,
                appearDelay: 0.3,
                payload: {
                    caption: translate("overlay.win-bet-amount", language),
                    fill: 0x000,
                    alpha: 0.8,
                    fontFamily: "MontrenaRough",
                    fontSize: "32px",
                    paddingX: 950,
                    offsetX: 0,
                    offsetY: 46,
                },
            },
        },
        duration: 1.3,
        fastDuration: 1.3,
        fullscreen: true,
        width: 2567,
        height: 1445,
        audio: {
            url: "/assets/sounds/RS_win",
        },
    }
);

const getBigWinOverlay = (_device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            overlay: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "contain",
                },
            },
            particles: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/RS_bigwin_particles.json",
                    fit: "contain",
                    animation: "main",
                    autoplay: true,
                    loop: false,
                },
            },
            coins: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/RS_bigwin_coins.json",
                    fit: "contain",
                    animation: "main",
                    autoplay: true,
                    loop: false,
                },
            },
            modal: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/RS_bigwin_modal.json",
                    fit: "contain",
                    animation: "main",
                    autoplay: true,
                    loop: false,
                },
            },
            title: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/RS_bigwin_title.json",
                    fit: "contain",
                    animation: "main",
                    autoplay: true,
                    loop: false,
                },
            },
            bonus: {
                type: GameFormElementType.Text,
                appearDelay: 1.3,
                payload: {
                    fit: "shrink",
                    source: "winnings",
                    preCaption: "{CURRENCY}",
                    fontFamily: "MontrenaRough",
                    decimals: 2,
                    animated: true,
                    animationDuration: 7,
                    fontSize: 100,
                    fill: 0x370000,
                    offsetX: 0,
                    offsetY: -40,
                    paddingX: 950,
                },
            },
            click: {
                type: GameFormElementType.Text,
                appearDelay: 9.3,
                payload: {
                    caption: translate("overlay.click", language),
                    fill: 0x000,
                    alpha: 0.8,
                    fontFamily: "MontrenaRough",
                    fontSize: "24px",
                    paddingX: 1135,
                    offsetX: 0,
                    offsetY: 40,
                },
            },
        },
        duration: 10,
        fastDuration: 10,
        canSkip: "after-duration",
        fullscreen: true,
        width: 2567,
        height: 1445,
        audio: {
            url: "/assets/sounds/RS_big_win",
        },
    }
);

const getPickEmOverlay = (_device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            overlay: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "contain",
                },
            },
            image: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/large-0.json",
                    fit: "contain",
                    autoplay: true,
                },
            },
            title: {
                type: GameFormElementType.Text,
                appearDelay: 1,
                payload: {
                    caption: translate("overlay.pickem-title", language),
                    fill: 0x370000,
                    fontFamily: "MontrenaRough",
                    fontSize: "80px",
                    paddingX: 950,
                    offsetX: 0,
                    offsetY: -60,
                },
            },
            ...(
                Object.fromEntries(
                    translate("overlay.pickem", language)
                        .split("\r\n")
                        .map(
                            (s, i) => (
                                [
                                    `text_${i}`,
                                    {
                                        type: GameFormElementType.Text,
                                        appearDelay: 1,
                                        payload: {
                                            caption: s,
                                            fill: 0x000,
                                            alpha: 0.8,
                                            fontSize: "20px",
                                            fontWeight: "800",
                                            paddingX: 950,
                                            offsetX: 0,
                                            offsetY: 28 + (i * 24),
                                        },
                                    },
                                ]
                            )
                        )
                )
            ),
        },
        // audio: {
        //     url: "/assets/sounds/RS_pickbonus_start",
        // },
        // duration: 3,
        fastDuration: 3,
        canSkip: "always",
        fullscreen: true,
        width: 2567,
        height: 1445,
    }
);

const getFreeSpinEnterOverlay = (_device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            overlay: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "contain",
                },
            },
            image: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/overlays/large-0.json",
                    fit: "contain",
                    autoplay: true,
                },
            },
            symbol: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/RS_antivenom.png",
                    // animation: "main",
                    // animated: false,
                    // loop: false,
                    // fit: "cover",
                    width: 100,
                    height: 100,
                    offsetX: 1234,
                    offsetY: 708,
                },
            },
            title: {
                type: GameFormElementType.Text,
                payload: {
                    caption: translate("overlay.freespin-title", language),
                    fill: 0x370000,
                    fontFamily: "MontrenaRough",
                    fontSize: "80px",
                    paddingX: 1000,
                    offsetX: 0,
                    offsetY: -116,
                },
            },
            ...(
                Object.fromEntries(
                    translate("overlay.freespin", language)
                        .split("\r\n")
                        .map(
                            (s, i) => (
                                [
                                    `text_${i}`,
                                    {
                                        type: GameFormElementType.Text,
                                        payload: {
                                            caption: s,
                                            fill: 0x000,
                                            alpha: 0.8,
                                            fontSize: "20px",
                                            fontWeight: "800",
                                            paddingX: 1000,
                                            offsetX: 0,
                                            offsetY: (-52) + (i * 162),
                                        },
                                    },
                                ]
                            )
                        )
                )
            ),
            click: {
                type: GameFormElementType.Text,
                payload: {
                    caption: translate("overlay.click", language),
                    fill: 0x000,
                    alpha: 0.8,
                    fontFamily: "MontrenaRough",
                    fontSize: "24px",
                    paddingX: 1135,
                    offsetX: 0,
                    offsetY: 162,
                },
            },
        },
        // audio: {
        //     url: `/assets/sounds/${language}/RS_freespins_start`,
        // },
        delay: 1,
        fastDuration: 2,
        canSkip: "always",
        blockExit: false,
        fullscreen: true,
        width: 2567,
        height: 1445,
    }
);

const getFreeSpinStatsOverlay = (_device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            overlay: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "contain",
                },
            },
            image: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/overlays/large-0.json",
                    fit: "contain",
                    autoplay: true,
                },
            },
            bonus: {
                type: GameFormElementType.Text,
                payload: {
                    fit: "shrink",
                    source: "winnings",
                    preCaption: "{CURRENCY}",
                    fontFamily: "MontrenaRough",
                    decimals: 2,
                    animated: true,
                    animationDuration: 3,
                    fontSize: 96,
                    fill: 0x370000,
                    offsetX: 0,
                    offsetY: -44,
                    paddingX: 950,
                },
            },
            text1: {
                type: GameFormElementType.Text,
                payload: {
                    caption: translate("overlay.freespin-stats", language),
                    fill: 0x000,
                    alpha: 0.8,
                    fontFamily: "MontrenaRough",
                    fontSize: "32px",
                    paddingX: 950,
                    offsetX: 0,
                    offsetY: 36,
                },
            },
            click: {
                type: GameFormElementType.Text,
                payload: {
                    caption: translate("overlay.click", language),
                    fill: 0x000,
                    alpha: 0.8,
                    fontFamily: "MontrenaRough",
                    fontSize: "24px",
                    paddingX: 1135,
                    offsetX: 0,
                    offsetY: 130,
                },
            },
        },
        // audio: {
        //     url: `/assets/sounds/${language}/RS_freespins_end`,
        // },
        canSkip: "always",
        delay: 2,
        fastDuration: 8,
        fullscreen: true,
        width: 2567,
        height: 1445,
    }
);

export const getGame = (device: "desktop" | "mobile", language: string): Omit<RattleSnakeGameDefinition, "boards" | "symbolScaling" | "symbols" | "symbolSize"> => (
    {
        sounds: getGameSoundDefinitions(language),
        overlays: {
            "win": getWinOverlay(device, language),
            "big-win": getBigWinOverlay(device, language),
            "pickem": getPickEmOverlay(device, language),
            // "pickem-free-spin": getPickEmFreeSpinOverlay(device, language),
            "free-spin-enter": getFreeSpinEnterOverlay(device, language),
            // "free-spin-delayed": getFreeSpinDelayedOverlay(device, language),
            "free-spin-stats": getFreeSpinStatsOverlay(device, language),
            // "free-spin-exit": getFreeSpinExitOverlay(device),
        },
    }
);

const GameReelDefinition: Omit<GameReelsDefinition<RattleSnakeGameSymbol, RattleSnakeGameNumberOfReels>, "symbols"> = {
    windowsSize: 3,
    inner: {
        // offsetX: 25,
        // offsetY: 50,
        offsetX: 0,
        offsetY: 0,
    },
    outer: {
        // offsetX: 675,
        // offsetY: 332,
        offsetX: 717,
        offsetY: 402,
    },
    size: 228,
    gap: 0,
    anticipation: {
        sound: {
            url: [
                "/assets/sounds/RS_anticipation_v1",
                "/assets/sounds/RS_anticipation_v2",
                "/assets/sounds/RS_anticipation_v3",
            ],
        },
        url: "/assets/reel/anticipation-0.json",
        blend: PIXI.BLEND_MODES.SCREEN,
        width: 450,
        height: 900,
    },
    pickem: {
        url: "/assets/reel/pick-0.json",
        blend: PIXI.BLEND_MODES.SCREEN,
        width: 420,
        height: 420,
    },
    ease: "M0,0,C0.173,0,0.242,-0.024,0.322,0.07,0.401,0.163,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.864,0.726,0.956,0.799,1.02,0.869,1,1,1",
    direction: -1,
    duration: 1,
    speed: 2,
    spinGranularDelay: 0.1,
    resultFixedDelay: 0.1,
    resultGranularDelay: 0.5,
    anticipationDelay: 2,
    // pickEmDelay: 1,
};

const GameMainBoardBackground: GameBoardDefinition<RattleSnakeGameSymbol, RattleSnakeGameNumberOfReels>["background"] = {
    images: [
        {
            url: "/assets/backgrounds/RS_Background.jpg",
            fit: "contain",
            autoplay: false,
        },
        {
            url: "/assets/backgrounds/RS_background_clouds.json",
            fit: "contain",
            autoplay: true,
            loop: true,
            animation: "main",
            speed: 0.25,
        },
    ],
    music: {
        url: "/assets/sounds/RS_theme_main",
        fadeIn: 0.3,
        fadeOut: 0.3,
        loop: true,
    },
};

const GameBonusBoardBackground: GameBoardDefinition<RattleSnakeGameSymbol, RattleSnakeGameNumberOfReels>["background"] = {
    images: [
        {
            url: "/assets/backgrounds/RS_Background_FS.jpg",
            fit: "contain",
            autoplay: false,
        },
    ],
    music: {
        url: "/assets/sounds/RS_theme_fs",
        fadeIn: 0.3,
        fadeOut: 0.3,
        loop: true,
    },
};

const getGameSoundDefinitions = (language: string): GameDefinition<RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay, GameSound, RattleSnakeGameNumberOfReels>["sounds"] => (
    {
        // "enter": {
        //     url: "/assets/sounds/RS_game_intro_v1",
        // },
        "spin": [
            {
                url: "/assets/sounds/RS_reelspin_v1",
                fadeIn: 0.2,
                loop: true,
            },
        ],
        "land": {
            url: [
                "/assets/sounds/RS_reelstop_v1",
                "/assets/sounds/RS_reelstop_v2",
                "/assets/sounds/RS_reelstop_v3",
                "/assets/sounds/RS_reelstop_v4",
                "/assets/sounds/RS_reelstop_v5",
            ],
        },
        "picked": {
            url: "/assets/sounds/RS_bonus_land_v1",
        },
        "pick-end": {
            url: `/assets/sounds/${language}/RS_pickbonus_end`,
        },
    }
);

const RattleSnakeGamePaylineDefinition: GamePaylineDefinition = {
    sound: {
        url: "/assets/sounds/RS_payline",
    },

    fireball: {
        url: "/assets/reel/fireball-0.json",
        blend: PIXI.BLEND_MODES.SCREEN,
        autoplay: true,
        width: 200,
        height: 200,
    },

    // - the alternate payline asset
    url: "/assets/reel/payline-0.json",
    blend: PIXI.BLEND_MODES.SCREEN,
    autoplay: true,

    // the actual size of usuble part of the asset
    width: 190,
    height: 160,

    // the max width of the asset to be scalled, compared to the `width` value
    maxWidth: 190,

    // the amount of pixels to be overlapped into the next asset
    paddingX: 65,
    paddingY: 0,

    delay: 0,
};

const getGameDesktopControls = (board: "game" | "fs", _language: string): GameFormDefinition => (
    {
        elements: {
            "background": {
                type: GameFormElementType.Image,
                payload: {
                    url: `/assets/controls/${board}/control-background.json`,
                    fit: "contain",
                    width: 2567,
                    height: 1445,
                } as GameImageDefinition,
            },
            "fast-mode": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${board}/control-fast-mode.json`,
                    action: "toggle-fast-mode",
                    width: 189,
                    height: 101,
                    hitArea: [ -86, -43, 159, 72 ],
                    offsetX: -1450,
                    offsetY: -323,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/RS_button",
                    },
                },
            },
            "auto-play": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${board}/control-auto-play.json`,
                    action: "toggle-auto-play",
                    width: 189,
                    height: 103,
                    hitArea: [ -86, -42, 158, 72 ],
                    offsetX: -1649,
                    offsetY: -323,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/RS_button",
                    },
                },
            },
            "spin": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${board}/control-spin.json`,
                    action: "spin",
                    width: 145,
                    height: 122,
                    hitArea: [ -52, -51, 101, 97 ],
                    offsetX: -1289,
                    offsetY: -328,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/RS_button_spin",
                    },
                },
            },
            "value": {
                type: GameFormElementType.Text,
                payload: {
                    source: board === "fs" ? "winnings" : "bet-amount",
                    preCaption: "{CURRENCY}",
                    decimals: 2,
                    fill: 0xFEE751,
                    fontFamily: "MontrenaSlab",
                    fontSize: 24,
                    align: "left",
                    width: 120,
                    height: 24,
                    offsetX: 92,
                    offsetY: 373,
                },
            },
            "bet-amount": {
                type: GameFormElementType.Text,
                payload: {
                    caption: board === "fs" ? "WIN AMOUNT" : "PLAY AMOUNT",
                    fill: 0x8B511F,
                    fontFamily: "MontrenaSlab",
                    fontSize: 16,
                    align: "left",
                    width: 120,
                    height: 19,
                    offsetX: 92,
                    offsetY: 408,
                },
            },
            "increase-bet": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${board}/control-increase-bet.json`,
                    action: "increase-bet",
                    width: 71,
                    height: 48,
                    hitArea: [ -24, -17, 53, 33 ],
                    offsetX: -999,
                    offsetY: -351,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/RS_coins",
                    },
                },
            },
            "decrease-bet": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${board}/control-decrease-bet.json`,
                    action: "decrease-bet",
                    width: 76,
                    height: 56,
                    hitArea: [ -23, -21, 53, 33 ],
                    offsetX: -999,
                    offsetY: -300,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/RS_coins",
                    },
                },
            },
            "max-bet": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${board}/control-max-bet.json`,
                    action: "max-bet",
                    width: 128,
                    height: 102,
                    hitArea: [ -37, -39, 93, 69 ],
                    offsetX: -885,
                    offsetY: -326,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/RS_coins_max",
                    },
                },
            },
        },
        width: 2567,
        height: 1445,
        offsetX: 2567 / 2,
        offsetY: 1445 / 2,
    }
);

// --------------- final constructed game config
export const getRattleSnakeGame = (language: string): RattleSnakeGameConfiguration<RattleSnakeGameDefinition> => (
    {
        homePage: {
            // background: "/assets/home/background.jpg",
            desktopSlides: [
                {
                    image: "/assets/home/desktop-0.png",
                    description: translate("home.freespin", language),
                },
                {
                    image: "/assets/home/desktop-1.png",
                    description: translate("home.pickem", language),
                },
            ],
            mobileSlides: [
                {
                    image: "/assets/home/mobile-0.png",
                    description: translate("home.freespin", language),
                },
                {
                    image: "/assets/home/mobile-1.png",
                    description: translate("home.pickem", language),
                },
            ],
        },
        guide: {
            desktopSlides: [
                {
                    image: "/assets/guide/desktop-0.png",
                    title: translate("guide-desktop.page1", language),
                },
                {
                    image: "/assets/guide/desktop-1.png",
                    title: translate("guide-desktop.page2", language),
                },
                {
                    image: "/assets/guide/desktop-2.png",
                    title: translate("guide-desktop.page3", language),
                },
                {
                    image: "/assets/guide/desktop-3.png",
                    title: translate("guide-desktop.page4", language),
                },
                {
                    image: "/assets/guide/desktop-4.png",
                    title: translate("guide-desktop.page5", language),
                },
                {
                    image: "/assets/guide/desktop-5.png",
                    title: translate("guide-desktop.page6", language),
                },
                {
                    image: "/assets/guide/desktop-6.png",
                    title: translate("guide-desktop.page7", language),
                },
                {
                    image: "/assets/guide/desktop-7.png",
                    title: translate("guide-desktop.page8", language),
                },
                {
                    image: "/assets/guide/desktop-8.png",
                    title: translate("guide-desktop.page9", language),
                },
            ],
            mobileSlides: [
                {
                    image: "/assets/guide/mobile-0.png",
                    title: translate("guide-mobile.page1", language),
                },
                {
                    image: "/assets/guide/mobile-1.png",
                    title: translate("guide-mobile.page2", language),
                },
                {
                    image: "/assets/guide/mobile-2.png",
                    title: translate("guide-mobile.page3", language),
                },
                {
                    image: "/assets/guide/mobile-3.png",
                    title: translate("guide-mobile.page4", language),
                },
                {
                    image: "/assets/guide/mobile-4.png",
                    title: translate("guide-mobile.page5", language),
                },
                {
                    image: "/assets/guide/mobile-5.png",
                    title: translate("guide-mobile.page6", language),
                },
                {
                    image: "/assets/guide/mobile-6.png",
                    title: translate("guide-mobile.page7", language),
                },
                {
                    image: "/assets/guide/mobile-7.png",
                    title: translate("guide-mobile.page8", language),
                },
                {
                    image: "/assets/guide/mobile-8.png",
                    title: translate("guide-mobile.page9", language),
                },
            ],
        },
        paylines: 15,
        desktopGame: {
            ...getGame("desktop", language),
            symbolSize: 228,
            symbolScaling: 1,
            boards: {
                main: {
                    symbolDefinitions: SymbolDesktopMainDefinitions,
                    background: GameMainBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/main-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/main-frame.json",
                        },
                        ...getGamePosition("desktop"),
                    },
                    reels: {
                        ...GameReelDefinition,
                        symbols: RattleSnakeGameMainReelSymbols,
                    },
                    payline: RattleSnakeGamePaylineDefinition,
                    in: getGameDustTransition("main"),
                    controls: getGameDesktopControls("game", language),
                    logo: {
                        url: "/assets/frame/RS_logo.json",
                        loop: true,
                        autoplay: true,
                        skin: "main",
                        animation: "main",
                    },
                    extras: [
                        {
                            url: "/assets/extra/RS_extras_logoposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_rope.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_wantedposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_tumbleweed.json",
                            loop: true,
                            autoplay: true,
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_plant.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_lizard.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/skeleton-0.json",
                            loop: true,
                            autoplay: true,
                        },
                        {
                            url: "/assets/extra/RS_extras_vulture.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                            clickAnimation: "fly",
                            hitArea: [ 2032, 406, 140, 110 ],
                            clickAudio: {
                                url: "/assets/sounds/RS_vulture",
                            },
                        },
                    ],
                },
                bonus: {
                    symbolDefinitions: SymbolDesktopFreeSpinDefinitions,
                    background: GameBonusBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/bonus-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/bonus-frame.json",
                        },
                        ...getGamePosition("desktop"),
                    },
                    reels: {
                        ...GameReelDefinition,
                        symbols: RattleSnakeGameBonusReelSymbols,
                    },
                    payline: RattleSnakeGamePaylineDefinition,
                    in: getGameDustTransition("fs"),
                    out: getGameDustTransition("main"),
                    controls: getGameDesktopControls("fs", language),
                    effects: [
                        {
                            url: "/assets/effects/RS_border_FS.json",
                            autoplay: true,
                            loop: true,
                            animation: "freespins",
                            alpha: 0.5,
                        },
                    ],
                    logo: {
                        url: "/assets/frame/RS_logo.json",
                        loop: true,
                        autoplay: true,
                        skin: "freespins",
                        animation: "freespins",
                        width: 2567,
                        height: 1445,
                    },
                    extras: [
                        {
                            url: "/assets/extra/RS_extras_logoposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_rope.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_wantedposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_plant.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_lizard.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/skeleton-fs-0.json",
                            loop: true,
                            autoplay: true,
                        },
                        {
                            url: "/assets/extra/RS_extras_vulture.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "freespins",
                            clickAnimation: "fly",
                            hitArea: [ 2032, 406, 140, 110 ],
                            clickAudio: {
                                url: "/assets/sounds/RS_vulture",
                            },
                        },
                    ],
                },
            },
            camera: {
                width: 2567,
                height: 1445,
                maxScale: 1,
                innerWidth: 1920,
                innerHeight: 1080,
            },
            density: 1,
        },
        mobileGame: {
            ...getGame("mobile", language),
            symbolSize: 228,
            symbolScaling: 1,
            boards: {
                main: {
                    symbolDefinitions: SymbolMobileMainDefinitions,
                    background: GameMainBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/main-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/main-frame.json",
                        },
                        ...getGamePosition("mobile"),
                    },
                    reels: {
                        ...GameReelDefinition,
                        symbols: RattleSnakeGameMainReelSymbols,
                    },
                    payline: RattleSnakeGamePaylineDefinition,
                    in: getGameDustTransition("main"),
                    controls: undefined,
                    logo: {
                        url: "/assets/frame/RS_logo.json",
                        loop: true,
                        autoplay: true,
                        skin: "main",
                        animation: "main",
                    },
                    extras: [
                        {
                            url: "/assets/extra/RS_extras_logoposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_rope.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_wantedposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_tumbleweed.json",
                            loop: true,
                            autoplay: true,
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_plant.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_lizard.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/skeleton-0.json",
                            loop: true,
                            autoplay: true,
                        },
                        {
                            url: "/assets/extra/RS_extras_vulture.json",
                            loop: true,
                            autoplay: true,
                            skin: "main",
                            animation: "main",
                            clickAnimation: "fly",
                            hitArea: [ 2032, 406, 140, 110 ],
                            clickAudio: {
                                url: "/assets/sounds/RS_vulture",
                            },
                        },
                    ],
                },
                bonus: {
                    symbolDefinitions: SymbolMobileFreeSpinDefinitions,
                    background: GameBonusBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/bonus-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/bonus-frame.json",
                        },
                        ...getGamePosition("mobile"),
                    },
                    reels: {
                        ...GameReelDefinition,
                        symbols: RattleSnakeGameBonusReelSymbols,
                    },
                    payline: RattleSnakeGamePaylineDefinition,
                    in: getGameDustTransition("fs"),
                    out: getGameDustTransition("main"),
                    controls: undefined,
                    effects: [
                        {
                            url: "/assets/effects/RS_border_FS.json",
                            autoplay: true,
                            loop: true,
                            animation: "freespins",
                            alpha: 0.5,
                        },
                    ],
                    logo: {
                        url: "/assets/frame/RS_logo.json",
                        loop: true,
                        autoplay: true,
                        skin: "freespins",
                        animation: "freespins",
                        width: 2567,
                        height: 1445,
                    },
                    extras: [
                        {
                            url: "/assets/extra/RS_extras_logoposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_rope.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_wantedposter.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_plant.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/RS_extras_lizard.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "main",
                        },
                        {
                            url: "/assets/extra/skeleton-fs-0.json",
                            loop: true,
                            autoplay: true,
                        },
                        {
                            url: "/assets/extra/RS_extras_vulture.json",
                            loop: true,
                            autoplay: true,
                            skin: "freespins",
                            animation: "freespins",
                            clickAnimation: "fly",
                            hitArea: [ 2032, 406, 140, 110 ],
                            clickAudio: {
                                url: "/assets/sounds/RS_vulture",
                            },
                        },
                    ],
                },
            },
            camera: {
                width: 2567,
                height: 1445,
                maxScale: 1,
                innerWidth: 1920,
                innerHeight: 1200,
            },
            forceLandscape: true,
            density: 1.5,
        },
    }
);
