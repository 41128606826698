import * as React from "react";
import { CheckboxProperties } from "./checkbox.properties";
import classNames from "classnames";
import styles from "./checkbox.module.scss";

export class Checkbox extends React.PureComponent<CheckboxProperties> {
    public render(): React.ReactNode {
        const { isDisabled, children, className, style = "form", isActive } = this.props;

        return <label
            className={
                classNames(
                    className,
                    styles.checkbox,
                    {
                        [styles.form]: style === "form",
                        [styles.game]: style === "game",
                        [styles.disabled]: isDisabled,
                        [styles.checked]: isActive,
                    }
                )
            }
        >
            <input
                onChange={isDisabled ? undefined : this.onToggle}
                disabled={isDisabled}
                checked={!!isActive}
                type="checkbox"
            />
            {!!React.Children.count(children) && <span>{children}</span>}
        </label>;
    }

    private onToggle = () => {
        if (!!this.props.isDisabled || !this.props.onToggle) {
            return false;
        }

        void this.props.onToggle();
    };
}
