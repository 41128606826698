import * as React from "react";
import { AppProperties } from "./app.properties";
import { GameConfiguration, GameTypes } from "@tgg/shared/src";
import { RattleSnakeGamePage } from "./components/game-page/rattle-snake-game-page.component";

const CurrencyShortForm: { [index: string]: string } = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
};

export default class App extends React.PureComponent<AppProperties, {
    userId?: number;
    balance?: number;
    currency?: string;
    config?: GameConfiguration;
    token?: string;
    language: string;
}> {
    public constructor(props: AppProperties) {
        super(props);
        let token: string | undefined;
        let language = "en";
        if (typeof window === "object") {
            const url = new URL(window.location.href);
            token = url.searchParams.get("playerToken") || undefined;
            language = url.searchParams.get("lang")?.toLowerCase().substring(0, 2) || language;

            if (
                !(
                    [
                        "en",
                        "ru",
                        "es",
                    ].includes(language)
                )
            ) {
                language = "en";
            }
        }

        this.state = {
            token,
            language,
        };
    }

    public componentDidMount(): void {
        if (this.state.token) {
            this.props.sessionService.getOrCreateSession(this.state.token, GameTypes.Slots).then(
                (s) => {
                    this.props.financeService.onBalanceUpdated.sub(
                        (b) => this.setState(
                            {
                                balance: b.amount,
                                currency: b.currency && typeof CurrencyShortForm[b.currency] !== "undefined" ? CurrencyShortForm[b.currency] : b.currency,
                            }
                        )
                    );
                    this.props.slotsService.setToken(s.token);
                    this.props.financeService.setToken(s.token);
                    void this.props.financeService.connectWebSocket();
                    this.setState(
                        {
                            userId: s.userId,
                            config: s.config ?? undefined,
                        }
                    );
                },
                (e: string | Error) => alert(`Failed to authenticate: ${e.toString()}`)
            );
        }

        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    public componentWillUnmount(): void {
        this.props.financeService.onBalanceUpdated.clear();
        window.removeEventListener("resize", this.onResize);
    }

    public render(): React.ReactNode {
        return <RattleSnakeGamePage
            userId={this.state.userId}
            slotsService={this.props.slotsService}
            balance={this.state.balance}
            currency={this.state.currency}
            config={this.state.config}
            isDemo={!this.state.token}
            language={this.state.language}
        />;
    }

    private onResize = () => {
        if (typeof window === "undefined" || typeof document === "undefined") {
            return;
        }

        document.body.style.setProperty("--screen-height", window.innerHeight ? `${window.innerHeight}px` : "100vh");
    };
}
