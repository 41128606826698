import * as PIXI from "pixi.js";
import { GameBoardDefinition, GameDefinition, GameSymbolAnimationDefinition } from "../../game.properties";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameSymbolLayerProps } from "./game-symbol-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import { Spine } from "@pixi-spine/all-4.1";

type GameSymbolAssetIndex<TBoardName extends string = string, TSymbols extends string = string> = `GameSymbol_${TBoardName}_${TSymbols}`;

export class GameSymbolLayer<TSymbols extends string = string> extends PixiJSRenderingLayer<
GameConfiguration,
{
    wrapper: PIXI.Container;
},
GameSymbolAssetIndex<string, TSymbols>,
GameSymbolLayerProps<TSymbols>,
PIXI.Container
> {
    private currentSymbol: TSymbols | undefined;
    private currentSprite: PIXI.AnimatedSprite | Spine | undefined;
    private sprites: Map<TSymbols, PIXI.AnimatedSprite | Spine> | undefined;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly game: GameDefinition,
        public readonly board: GameBoardDefinition<TSymbols, any>,
        public readonly boardName: string
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "normal",
            },
            {
                wrapper: new PIXI.Container(),
            },
            Object.fromEntries(
                [
                    ...(
                        Object
                            .keys(board.symbolDefinitions)
                            .map(
                                (k) => ([ `GameSymbol_${boardName}_${k}`, board.symbolDefinitions[k as TSymbols].url ])
                            )
                    ),
                ]
            )
        );
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        this.updateSize();
    }

    public setProps(props: Partial<GameSymbolLayerProps<TSymbols>>): void {
        super.setProps(props);
        if (props.symbol !== this.currentSymbol) {
            this.currentSymbol = props.symbol;
            this.updateSymbol();
        }
    }

    public focus(): void {
        this.setProps(
            {
                state: "focus",
            }
        );
    }

    public activate(): void {
        this.setProps(
            {
                state: "active",
            }
        );
    }

    public deactivate(): void {
        this.setProps(
            {
                state: "normal",
            }
        );
    }

    protected stateChanged(): void {
        this.updateAnimation();
    }

    protected loaded(): void {
        super.loaded();

        if (!this.sprites) {
            this.sprites = new Map<TSymbols, PIXI.AnimatedSprite>();
        }

        for (const symbol in this.board.symbolDefinitions) {
            if (!Object.prototype.hasOwnProperty.call(this.board.symbolDefinitions, symbol)) {
                continue;
            }

            const definition = this.board.symbolDefinitions[symbol];
            const asset = this.assets[`GameSymbol_${this.boardName}_${symbol}`];
            if (!!asset.textures) {
                const sprite = new PIXI.AnimatedSprite(asset.textures);
                sprite.animationSpeed = definition.speed ?? 0.5;
                // sprite.filters = [ new PIXI.filters.FXAAFilter() ];
                sprite.visible = false;
                sprite.renderable = false;
                sprite.alpha = 0;
                this.sprites.set(symbol as TSymbols, sprite);
            } else if (!!asset.spine) {
                const spine = new Spine(asset.spine);
                spine.autoUpdate = true;
                if (definition.skin) {
                    spine.skeleton.setSkinByName(definition.skin);
                }
                spine.state.timeScale = definition.speed ?? 1;
                spine.visible = false;
                spine.renderable = false;
                spine.alpha = 0;
                this.sprites.set(symbol as TSymbols, spine);
            } else {
                continue;
            }
        }

        this.updateSymbol();
    }

    private updateAnimation() {
        if (!this.props.symbol) {
            return;
        }
        const definition = this.board.symbolDefinitions[this.props.symbol];

        if (this.currentSprite instanceof PIXI.AnimatedSprite) {
            if (this.props.state === "active") {
                this.currentSprite.loop = true;
                if (!this.currentSprite.playing) {
                    this.currentSprite.gotoAndPlay(0);
                }
            } else if (this.props.state === "focus") {
                this.currentSprite.loop = false;
                if (!this.currentSprite.playing) {
                    this.currentSprite.gotoAndPlay(0);
                }
            } else if (this.props.state === "normal") {
                if (!!this.currentSprite.playing) {
                    this.currentSprite.loop = false;
                }
            }
        } else if (this.currentSprite instanceof Spine) {
            if (this.props.state === "active") {
                if (!!definition.activeAnimations) {
                    this.applySpineAnimation(this.currentSprite, definition.activeAnimations);
                }
            } else if (this.props.state === "focus") {
                if (!!definition.focusAnimations) {
                    this.applySpineAnimation(this.currentSprite, definition.focusAnimations);
                }
            } else if (this.props.state === "normal") {
                this.applySpineAnimation(this.currentSprite, definition.idleAnimations ?? []);
            }
        }
    }

    private updateSymbol() {
        if (!this.props.symbol) {
            return;
        }

        const definition = this.board.symbolDefinitions[this.props.symbol];
        const asset = this.assets[`GameSymbol_${this.boardName}_${this.props.symbol}`];
        if (!asset || !definition || (!asset.textures && !asset.spine)) {
            return;
        }

        const newSprite = this.sprites?.get(this.props.symbol);
        if (newSprite === this.currentSprite || !newSprite) {
            return;
        }

        if (this.currentSprite) {
            if (this.currentSprite instanceof PIXI.AnimatedSprite) {
                this.currentSprite.gotoAndStop(0);
            } else {
                this.currentSprite.state.clearTrack(0);
                this.currentSprite.skeleton.setToSetupPose();
            }
            this.currentSprite.visible = false;
            this.currentSprite.renderable = false;
            this.currentSprite.alpha = 0;
            this.shapes.wrapper.removeChild(this.currentSprite);
        }

        newSprite.visible = true;
        newSprite.renderable = true;
        newSprite.alpha = 1;
        if (newSprite instanceof Spine) {
            if (!!definition.idleAnimations) {
                this.applySpineAnimation(newSprite, definition.idleAnimations);
            }
        }
        this.currentSprite = newSprite;
        this.shapes.wrapper.addChild(this.currentSprite);

        this.updateSize();
        this.updateAnimation();
    }

    private updateSize() {
        if (!this.props.symbol || !this.currentSprite) {
            return;
        }

        const definition = this.board.symbolDefinitions[this.props.symbol];
        const asset = this.assets[`GameSymbol_${this.boardName}_${this.props.symbol}`];
        if (!asset || !definition || (!asset.textures && !asset.spine)) {
            return;
        }

        const width = this.width;
        const height = this.height;
        let imageWidth = this.game.symbolSize;
        let imageHeight = this.game.symbolSize;
        const factor = Math.min(width / imageWidth, height / (imageHeight * definition.heightFactor)) * this.game.symbolScaling;
        imageWidth = (imageWidth * factor);
        imageHeight = ((imageHeight * definition.heightFactor) * factor);
        const offsetX = ((width - imageWidth) / 2);
        const offsetY = (height - imageHeight) / 2;

        this.currentSprite.scale.x = factor;
        this.currentSprite.scale.y = factor;
        if (this.currentSprite instanceof Spine) {
            this.currentSprite.x = imageWidth / 2;
            this.currentSprite.y = imageHeight / 2;
        } else {
            this.currentSprite.x = 0;
            this.currentSprite.y = 0;
        }
        this.shapes.wrapper.x = offsetX;
        this.shapes.wrapper.y = offsetY;
    }

    private applySpineAnimation(spine: Spine, animations: GameSymbolAnimationDefinition[] | GameSymbolAnimationDefinition) {
        if (!Array.isArray(animations)) {
            animations = [ animations ];
        }

        if (!animations.length) {
            spine.state.clearTrack(0);
            spine.skeleton.setToSetupPose();
        }

        const currentAnimationName = !!spine.state.tracks[0]?.getAnimationTime() ? spine.state.tracks[0]?.animation?.name : undefined;
        const lastAnimation = animations[animations.length - 1];
        const lastAnimationName = typeof lastAnimation === "string" ? lastAnimation : lastAnimation?.name;
        if (lastAnimationName === currentAnimationName) {
            return;
        }

        for (let i = 0; i < animations.length; i++) {
            const animation = animations[i];
            const animationName = typeof animation === "string" ? animation : animation.name;
            const isAnimationLoopped = typeof animation === "string" ? false : animation.loop;

            if (i === 0 && currentAnimationName === animationName) {
                continue;
            }

            if (!spine.state.hasAnimation(animationName)) {
                continue;
            }

            if (i === 0) {
                spine.state.setAnimation(0, animationName, isAnimationLoopped);
            } else {
                spine.state.addAnimation(0, animationName, isAnimationLoopped);
            }

            if (isAnimationLoopped) {
                break;
            }
        }
    }
}
