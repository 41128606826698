import { BaseService } from "@tgg/shared";
import { SlotsGameDto } from "./dtos/slots-game.dto";
import { SlotsPickDto } from "./dtos/slots-pick.dto";
import { SlotsServiceBase } from "./slots-service.base";
import { SlotsSpinDto } from "./dtos/slots-spin.dto";

export class SlotsService extends BaseService implements SlotsServiceBase {
    private token?: string;

    public constructor(baseUrl?: string) {
        super(baseUrl);
    }

    public async spin(dto: SlotsSpinDto): Promise<SlotsGameDto> {
        return await this.requestRest<SlotsGameDto, SlotsSpinDto>(
            "/Slots/spin",
            {},
            {},
            "POST",
            dto
        );
    }

    public async pick(dto: SlotsPickDto): Promise<SlotsGameDto> {
        return await this.requestRest<SlotsGameDto, SlotsPickDto>(
            "/Slots/pick",
            {},
            {},
            "POST",
            dto
        );
    }

    public getToken(): string | undefined {
        return this.token;
    }

    public setToken(value: string): void {
        this.token = value;
    }
}
