import * as PIXI from "pixi.js";
import { GameConfiguration } from "./game-configuration.interface";
import { ITextStyle } from "pixi.js";
import { PixiJSLayoutProperties } from "../../common/utilities/pixijs-rendering-layer";

export type NumberRange<T extends number> = number extends T ? number : _NumberRange<T, []>;
type _NumberRange<T extends number, R extends unknown[]> = R["length"] extends T ? R[number] : _NumberRange<T, [R["length"], ...R]>;
export type GameReel<TReels extends number> = `reel${NumberRange<5>}`; // TODO: FIX TYPE

export type GameBinding = "score" | "balance" | "bet-amount" | "winnings";
export type GameAction = "spin" | "min-bet" | "max-bet" | "increase-bet" | "decrease-bet" | "toggle-auto-play" | "toggle-fast-mode";
export type GameSound = "enter" | "spin" | "land" | "win" | "big-win" | "pick-start" | "picked" | "pick-end";
export enum GameFormElementType {
    Image = "image",
    Button = "button",
    Text = "text",
    Rectangle = "rectangle",
    Tab = "tab"
}

interface GameFormElementMap {
    [GameFormElementType.Image]: GameImageDefinition;
    [GameFormElementType.Button]: GameButtonDefinition;
    [GameFormElementType.Text]: GameTextDefinition;
    [GameFormElementType.Rectangle]: GameRectangleDefinition;
    [GameFormElementType.Tab]: GameTabDefinition;
}

export interface GameFormElementDefinition<T extends GameFormElementType = GameFormElementType, TValue = GameFormElementMap[T]> {
    type: T;
    payload: TValue;
    appearDelay?: number;
    appearDuration?: number;
}

export type GameActionsMap = {
    [K in GameAction]: (() => (Promise<void> | void));
};

export type GameBindingsMap = {
    [K in GameBinding]?: string | number;
};

export interface GameSoundDefinition {
    url: string | string[];
    delay?: number;
    loop?: boolean;
    stoppable?: boolean;
    fadeIn?: number;
    fadeOut?: number;
}

export interface GameFormElementBaseDefinition extends PixiJSLayoutProperties {
    /** the transparency */
    alpha?: number;
}

export interface GameRectangleDefinition extends GameFormElementBaseDefinition {
    /** The border radius of the rectangle */
    cornerRadius?: number;

    /** the color to be filled */
    background?: number;
}

export interface GameImageDefinition extends GameFormElementBaseDefinition {
    /** the address and size of the original asset, used for scaling */
    url: string;

    /** the sound to the played while the image is in playing state */
    sound?: GameSoundDefinition | GameSoundDefinition[];

    /** is loop */
    loop?: boolean;

    /** compositing blend mode */
    blend?: PIXI.BLEND_MODES;

    // /** image's alpha mode */
    // alpha?: number;

    /** sets the animation speed of the image */
    speed?: number;

    /** sprite animation's name */
    animation?: string;

    /** sprite click animation's name */
    clickAnimation?: string;

    /** sprite click animation's sound */
    clickAudio?: GameSoundDefinition | GameSoundDefinition[];

    /** spine's skin name */
    skin?: string;

    /** sets the initial start delay */
    startDelay?: number;

    /** sets the ending loop delay */
    endDelay?: number;

    /** the initial image state, if not overwittern */
    autoplay?: boolean;

    /**
     * the hit area used for clicking
       4 values results in a recangle[x, y, w, h], 3 values results in a circle[x, y, r]
       the origin of the coordinations is the anchor point of the asset
     */
    hitArea?: [number, number, number, number] | [number, number, number];
}

export interface GameTextDefinition extends Partial<ITextStyle>, GameFormElementBaseDefinition {
    /**
     * The content added before the actual caption
     */
    postCaption?: string;

    /**
     * The content added before the actual caption
     */
    preCaption?: string;

    /**
     * The content, used when source is missing
     */
    caption?: string;

    /**
     * The buttons binding source
     */
    source?: GameBinding;

    /**
     * The number of decimals for numeric values
     */
    decimals?: number;

    /**
     * A boolean value indicating if the value should be animated from zero up, only for numeric values
     */
    animated?: boolean;

    /**
     * The duration of the animation
     */
    animationDuration?: number;

    /**
     * margin is used for positioning of the caption,
       calculated from the asset's anchor point
     */
    marginX?: number;

    /**
     * margin is used for positioning of the caption,
       calculated from the asset's anchor point
     */
    marginY?: number;
}

export interface GameButtonDefinition extends GameImageDefinition, GameTextDefinition {
    action?: GameAction | (() => void);

    /**
     * the hit area used for clicking and mouse over
       4 values results in a recangle[x, y, w, h], 3 values results in a circle[x, y, r]
       the origin of the coordinations is the anchor point of the asset
     */
    hitArea: [number, number, number, number] | [number, number, number];

    /** the sound to the played while the button is clicked */
    sound?: GameSoundDefinition | GameSoundDefinition[];
}

export type GameSymbolAnimationDefinition = string | {
    name: string;
    loop?: boolean
}

export interface GameSymbolDefinition {
    /** the address of the asset */
    url: string;

    /** sets the animation speed of the image */
    speed?: number;

    /** symbol's idle animation's name */
    idleAnimations?: GameSymbolAnimationDefinition[] | GameSymbolAnimationDefinition;

    /** symbol's focus animation's name */
    activeAnimations?: GameSymbolAnimationDefinition[] | GameSymbolAnimationDefinition;

    /** symbol's focus animation's name */
    focusAnimations?: GameSymbolAnimationDefinition[] | GameSymbolAnimationDefinition;

    /** spine's skin name */
    skin?: string;

    heightFactor: number;
    sound?: GameSoundDefinition | GameSoundDefinition[];
}

export interface GameReelsDefinition<TSymbols extends string = string, TReels extends number = 1> {
    symbols: {
        [K in GameReel<TReels>]: TSymbols[]
    };

    /**
     * outer block offsets from the frame
     */
    outer: {
        offsetX: number;
        offsetY: number;
    };

    /**
     * inner block offsets from the outer block
     */
    inner: {
        offsetX: number;
        offsetY: number;
    };

    /**
     * anticipation image, this image is scaled to the size of the reel
     * this image is position on the anchor point to the top-left of the outer block
     */
    anticipation: GameImageDefinition;

    /**
     * pickem image, this image is scaled to the size of the symbol
     * this image is position on the anchor point to the top-left of the outer block
     */
    pickem: GameImageDefinition;

    /** the reel movement easing function, must be uniform in the middle */
    ease: string;

    /** size of each reel */
    size: number;

    /** the gap in between reels */
    gap: number;

    /** the direction of the reel, x < 0: inverse, x > 0: normal, x = 0: alternate */
    direction: number;

    /** the minimum duration of the animation as well as the speed multiplier */
    duration: number;

    /** the speed multiplier of the spin in the said duration */
    speed: number;

    /**
     * additional delay added to the start of each animation
     * delay is multiplied by the index of the reel
     */
    spinGranularDelay: number;

    /**
     * additional delay added to the end of the spin animation
     */
    resultFixedDelay: number;

    /**
     * additional delay added to the end of the spin animation
     * delay is multiplied by the index of the reel
     */
    resultGranularDelay: number;

    /**
     * the delay added to the `resultDelay` when entering into the anticipation state
     */
    anticipationDelay?: number;

    // /**
    //  * the delay added before showing the pickem slots
    //  */
    // pickEmDelay?: number;

    /** number of symbols visible vertically */
    windowsSize: number;
}

export interface GameBoardFrameDefinition extends PixiJSLayoutProperties {
    background: GameImageDefinition;
    foreground: GameImageDefinition;
    marginPortraitX?: number;
    marginPortraitY?: number;
    marginLandscapeX?: number;
    marginLandscapeY?: number;
}

export interface GameBoardDefinition<TSymbols extends string = string, TReels extends number = 1> {
    /** The page background */
    background: {
        images: GameImageDefinition[];
        music?: GameSoundDefinition | GameSoundDefinition[];
    };

    /** defines each symbol and provides asset */
    symbolDefinitions: Record<TSymbols, GameSymbolDefinition>;

    /**
     * provides information about the frame of the game, its background and foreground
       these assets should not be anchored as they are aligned center, instead position it from here
     */
    frame: GameBoardFrameDefinition;

    /** the reels definition and settings */
    reels: GameReelsDefinition<TSymbols, TReels>;

    /** the board payline definition */
    payline: GamePaylineDefinition;

    /** Out Transition */
    out?: GameTransitionDefinition;

    /** Out Transition */
    in?: GameTransitionDefinition;

    /** the footer form definition */
    controls?: GameFormDefinition;

    /** extra images */
    extras?: GameImageDefinition[];

    /** effect images */
    effects?: GameImageDefinition[];

    logo?: GameImageDefinition;
}

export interface GameFormDefinition extends GameFormElementBaseDefinition {
    elements: {
        [key: string]: GameFormElementDefinition;
    };
}

export interface GameTabDefinition extends GameFormElementBaseDefinition {
    tabs: {
        [key: string]: GameFormDefinition;
    };
    button: GameButtonDefinition;
}

export interface GameOverlayDefinition extends GameFormDefinition {
    audio?: GameSoundDefinition | GameSoundDefinition[];
    delay?: number;
    duration?: number;
    fastDuration?: number;
    canSkip?: "after-duration" | "always";
    width?: number;
    height?: number;
    fullscreen?: boolean;
    blockExit?: boolean;
    blockEnter?: boolean;
}

export interface GameTransitionDefinition extends GameOverlayDefinition {
    fullscreen?: true;
    inDelay?: number;
    outDelay?: number;
}

export interface GamePaylineDefinition extends GameImageDefinition {
    /**
     * Fireballs are what is seen on each side of the payline
     */
    fireball: GameImageDefinition;

    /**
     * the actual width of usuble part of the asset
     */
    width: number;

    /**
     * the actual height of usuble part of the asset
     */
    height: number;

    /**
     * the max width of the asset to be scalled, compared to the `width` value
     */
    maxWidth: number;

    /**
     * the amount of pixels to be overlapped into the next segment horizontally
     */
    paddingX: number;

    /**
     * the amount of pixels to be overlapped into the next asset
     */
    paddingY: number;

    /**
     * The minimum delay after payline appearance
     */
    delay: number;
}

export interface GameDefinition<
    TSymbols extends string = string,
    TBoards extends string = string,
    TOverlays extends string = string,
    TSounds extends GameSound = GameSound,
    TReels extends number = 1
> {
    /** anti alisiaging, has some problem on some ios devices */
    antiAliasing?: boolean;

    /** pixel density */
    density?: number;

    /** camera settings */
    camera?: {
        width: number;
        height: number;
        maxScale?: number;
        minScale?: number;
        innerWidth?: number;
        innerHeight?: number;
    };

    /** defines each game board, their reels and scatters needed to enter */
    boards: {
        [K in TBoards]: GameBoardDefinition<TSymbols, TReels>
    };

    /** defines each possible overlay */
    overlays: {
        [K in TOverlays]?: GameOverlayDefinition
    };

    /** defines all necessery audio assets */
    sounds: {
        [K in TSounds]?: GameSoundDefinition | GameSoundDefinition[]
    };

    /** the main symbol size, not inclduing the free space around */
    symbolSize: number;

    /** forced scaling on the symbol's image */
    symbolScaling: number;

    forceLandscape?: boolean;
}

export interface GameProperties<TBoards extends string = string> {
    isReady: boolean;
    defaultBoard: TBoards;
    game: GameDefinition;
    configuration: GameConfiguration;
}
