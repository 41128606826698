import * as PIXI from "pixi.js";
import { GameAction, GameBindingsMap, GameFormDefinition } from "../../game.properties";
import { GameButtonLayer } from "../button/game-button.layer";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameControlsLayerProps } from "./game-controls-layer.props";
import { GameFormLayer } from "../form/game-form.layer";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";

export class GameControlsLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    form: GameFormLayer;
},
"",
GameControlsLayerProps,
PIXI.Container
> {
    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        public readonly definition: GameFormDefinition,
        additionalActions: {
            [key: string]: (() => (Promise<void> | void));
        } = {}
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "disabled",
            },
            {
                form: new GameFormLayer(
                    configuration,
                    app,
                    definition,
                    {},
                    additionalActions
                ),
            }
        );
    }

    public destroy(): void {
        super.destroy();
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        this.shapes.form.resize(width, height);
    }

    public config(configuration: GameConfiguration): void {
        let shouldUpdateState = false;

        if (
            configuration.autoPlayRounds !== this.configuration.autoPlayRounds
        ) {
            shouldUpdateState = true;
        }

        if (
            configuration.isFastModeActive !== this.configuration.isFastModeActive
        ) {
            shouldUpdateState = true;
        }

        if (
            configuration.totalBetAmount !== this.configuration.totalBetAmount
        ) {
            shouldUpdateState = true;
        }

        super.config(configuration);

        if (shouldUpdateState) {
            this.stateChanged(this.props);
        }

        this.updateBindings(
            {
                "bet-amount": configuration.totalBetAmount,
                "balance": configuration.balance,
                "winnings": configuration.winnings,
                "score": undefined,
            }
        );
    }

    public updateBindings(bindings?: GameBindingsMap): void {
        if (bindings) {
            this.shapes.form.updateBindings(bindings);
        }
    }

    protected stateChanged(props: GameControlsLayerProps): void {
        if (props.state === "disabled") {
            for (const button of this.getButtonsByAction()) {
                button.setProps({ state: "disabled" });
            }
        } else if (props.state === "enabled") {
            for (const button of this.getButtonsByAction()) {
                switch (button.definition.action) {
                    case "toggle-auto-play":
                        button.setProps({ state: !!this.configuration.autoPlayRounds ? "active" : "normal" });
                        break;
                    case "toggle-fast-mode":
                        button.setProps({ state: !!this.configuration.isFastModeActive ? "active" : "normal" });
                        break;
                    default:
                        button.setProps({ state: "normal" });
                        break;
                }
            }
        } else if (props.state === "spinning") {
            for (const button of this.getButtonsByAction()) {
                switch (button.definition.action) {
                    case "spin":
                        button.setProps({ state: "active" });
                        break;
                    case "toggle-auto-play":
                        button.setProps({ state: !!this.configuration.autoPlayRounds ? "active" : "normal" });
                        break;
                    case "toggle-fast-mode":
                        button.setProps({ state: !!this.configuration.isFastModeActive ? "active" : "normal" });
                        break;
                    default:
                        button.setProps({ state: "disabled" });
                        break;
                }
            }
        } else if (props.state === "skipping") {
            for (const button of this.getButtonsByAction()) {
                switch (button.definition.action) {
                    case "toggle-auto-play":
                        button.setProps({ state: !!this.configuration.autoPlayRounds ? "active" : "normal" });
                        break;
                    case "toggle-fast-mode":
                        button.setProps({ state: !!this.configuration.isFastModeActive ? "active" : "normal" });
                        break;
                    default:
                        button.setProps({ state: "disabled" });
                        break;
                }
            }
        }
    }

    protected getButtonsByAction(action?: GameAction): GameButtonLayer[] {
        return Object.values(this.shapes.form.shapes)
            .filter((f) => (f instanceof GameButtonLayer) && (!action || f.definition.action === action))
            .map((f) => f as GameButtonLayer);
    }

    protected loaded(): void {
        super.loaded();
        this.resize(this.width, this.height);
    }
}
