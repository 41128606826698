import * as PIXI from "pixi.js";
import { ISpritesheetData } from "pixi.js";
import { PixiJSLayer } from "./pixijs-layer";
import { SkeletonData } from "@pixi-spine/all-4.1";

export type PixiJSRenderingShape<TConfig> = PIXI.Container | PixiJSLayer<TConfig>;
export type PixiJSRenderingShapeDefinition<TConfig> = PixiJSRenderingShape<TConfig> | (() => PixiJSRenderingShape<TConfig>);
export type PixiJSRenderingShapes<TConfig> = Record<string, PixiJSRenderingShape<TConfig>>;
export type PixiJSRenderingShapeDefinitions<TShapes, TConfig> = Record<keyof TShapes, PixiJSRenderingShapeDefinition<TConfig>>;

export interface PixiJSRenderingAsset {
    url: string;
    loaded: boolean;
    resource?: PIXI.LoaderResource;
    textures?: PIXI.Texture[];
    spine?: SkeletonData;
    videoControl?: HTMLVideoElement;
}

export interface PixiJSLayoutProperties {
    /** width of the element, this is used for the scaling proposes */
    width?: number;

    /** height of the element, this is used for the scaling proposes */
    height?: number;

    /** the element horizontal padding */
    paddingX?: number;

    /** the element vertical padding */
    paddingY?: number;

    /** the amount horizontal offset */
    offsetX?: number;

    /** the amount vertical offset */
    offsetY?: number;

    /** the max scale */
    maxScale?: number;

    /** the min scale */
    minScale?: number;

    /** auto resize mode */
    fit?: "cover" | "contain" | "grow" | "shrink";

    /** vertical alignment */
    verticalAlign?: "top" | "center" | "bottom";

    /** horizontal alignment */
    horizontalAlign?: "left" | "center" | "right";

    /** portrait overrides */
    portraitOverride?: Partial<Omit<PixiJSLayoutProperties, "portrait" | "landscape">>;

    /** portrait overrides */
    landscapeOverride?: Partial<Omit<PixiJSLayoutProperties, "portrait" | "landscape">>;
}

export interface PixiJSBoundingBox {
    center: [number, number];
    x: number;
    y: number;
    top: number;
    left: number;
    bottom: number;
    right: number;
    width: number;
    height: number;
    scale: number;
}

export type PixiJSRenderingAssetDefinition = string;
export type PixiJSRenderingAssets<TAssets extends string | number = string> = Record<TAssets, PixiJSRenderingAsset>;
export type PixiJSRenderingAssetDefinitions<TAssets extends string | number = string> = Record<TAssets, PixiJSRenderingAssetDefinition>;

export interface PixiJSRenderingProps<T extends string | number = string> {
    state: T;
}

export abstract class PixiJSRenderingLayer<
    TConfig = unknown,
    TShapes extends PixiJSRenderingShapes<TConfig> = any,
    TAssets extends string | number = string,
    TProps extends PixiJSRenderingProps = PixiJSRenderingProps,
    TContainer extends PIXI.Container = PIXI.Container,
> implements PixiJSLayer<TConfig> {
    public readonly shapes: TShapes;
    protected props: TProps;
    protected readonly assets: PixiJSRenderingAssets<TAssets>;
    protected width = 0;
    protected height = 0;
    protected x = 0;
    protected y = 0;
    protected rotation = 0;
    private lastState?: TProps["state"];
    private onLoadSignalBinding: unknown;
    private debugGraphic?: PIXI.Graphics;

    protected constructor(
        protected configuration: TConfig,
        protected app: PIXI.Application,
        public readonly container: TContainer,
        props: TProps,
        shapes: PixiJSRenderingShapeDefinitions<TShapes, TConfig> = {} as PixiJSRenderingShapeDefinitions<TShapes, TConfig>,
        assets: PixiJSRenderingAssetDefinitions<TAssets> = {} as PixiJSRenderingAssetDefinitions<TAssets>
    ) {
        this.props = { ...props };

        this.shapes = {} as TShapes;
        for (const key in shapes) {
            if (!Object.prototype.hasOwnProperty.call(shapes, key)) {
                continue;
            }

            let shape = shapes[key];
            if (!shape) {
                continue;
            }

            if (typeof shape === "function") {
                shape = shape();
            }

            this.shapes[key as keyof TShapes] = shape as unknown as TShapes[keyof TShapes];

            if (typeof (shape as PixiJSLayer<TConfig>).container !== "undefined") {
                if ((shape as PixiJSLayer<TConfig>).container !== this.container) {
                    this.container.addChild((shape as PixiJSLayer<TConfig>).container);
                }

                (shape as PixiJSLayer<TConfig>).config(configuration);
            } else {
                this.container.addChild(shape as PIXI.Container);
            }
        }

        this.assets = {} as PixiJSRenderingAssets<TAssets>;
        for (const key in assets) {
            if (!Object.prototype.hasOwnProperty.call(assets, key)) {
                continue;
            }

            const isAlreadyLoaded = typeof PIXI.Loader.shared.resources[key] !== "undefined" && !!PIXI.Loader.shared.resources[key];

            this.assets[key] = {
                url: assets[key],
                loaded: isAlreadyLoaded,
            };

            if (!isAlreadyLoaded) {
                PIXI.Loader.shared.add(key, assets[key]);
            }
        }

        this.onLoadSignalBinding = PIXI.Loader.shared.onComplete.once(
            () => {
                this.loaded();
            }
        );

        if (!PIXI.Loader.shared.loading && PIXI.Loader.shared.progress > 0) {
            setTimeout(
                () => {
                    this.loaded();
                },
                0
            );
        }

        this.app.ticker.add(this.onTick, this, PIXI.UPDATE_PRIORITY.NORMAL);
    }

    public destroy(): void {
        this.app.ticker.remove(this.onTick, this);
        PIXI.Loader.shared.onLoad.detach(this.onLoadSignalBinding as any);

        for (const key in this.shapes) {
            if (Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                const shape = this.shapes[key];
                if (!shape) {
                    continue;
                }

                shape.destroy({ children: true, texture: false, baseTexture: false });
            }
        }

        this.container.destroy();
    }

    public resize(width: number, height: number): void {
        if (this.width !== width) {
            this.width = width;
        }

        if (this.height !== height) {
            this.height = height;
        }

        if (!!this.debugGraphic) {
            this.debug();
        }
    }

    public move(x: number, y: number): void {
        if (this.x !== x) {
            this.container.x = x;
            this.x = x;
        }

        if (this.y !== y) {
            this.container.y = y;
            this.y = y;
        }
    }

    public rotate(r: number): void {
        if (this.rotation !== r) {
            this.container.rotation = r;
            this.rotation = r;
        }
    }

    public setProps(props: Partial<TProps>): void {
        this.props = {
            ...(this.props ?? {}),
            ...props,
        };

        if (this.lastState !== this.props.state) {
            this.stateChanged(this.props, this.lastState);
            this.lastState = props.state;
        }
    }

    public getProps(): TProps {
        return {
            ...(this.props ?? {}),
        };
    }

    public debug(): void {
        if (!this.debugGraphic) {
            this.debugGraphic = new PIXI.Graphics();
            this.container.addChildAt(this.debugGraphic, 0);
        }

        this.debugGraphic.clear();
        this.debugGraphic.beginFill(0xff0000);
        this.debugGraphic.alpha = 0.2;
        this.debugGraphic.drawRect(0, 0, this.width, this.height);
        this.debugGraphic.endFill();
    }

    public config(configuration: TConfig): void {
        this.configuration = configuration;

        for (const key in this.shapes) {
            if (Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                const shape = this.shapes[key];
                if (!shape) {
                    continue;
                }

                if (typeof (shape as PixiJSLayer<TConfig>).config !== "undefined") {
                    (shape as PixiJSLayer<TConfig>).config(configuration);
                }
            }
        }
    }

    protected tick(_dt: number): void {
        // ignore
    }

    protected loaded(): void {
        const loader = PIXI.Loader.shared;

        for (const key in this.assets) {
            if (!Object.prototype.hasOwnProperty.call(this.assets, key)) {
                continue;
            }

            if (typeof loader.resources[key] === "undefined" || !loader.resources[key]) {
                continue;
            }

            const resource = loader.resources[key];
            this.assets[key].resource = resource;

            switch (resource.type) {
                case PIXI.LoaderResource.TYPE.JSON:
                    if (!!resource.spineData) {
                        this.assets[key].spine = resource.spineData;
                    } else {
                        // monkey patch multi image spritesheets
                        let textures = Object.values(resource.spritesheet?.textures ?? {});
                        for (const child of resource.children) {
                            if (child.extension !== "json") {
                                continue;
                            }

                            textures.push(...Object.values(child.spritesheet?.textures ?? {}));
                        }

                        const animations = Object.keys(resource.spritesheet?.animations ?? {});
                        if (!!animations.length) {
                            const animationFrames = (resource.data as ISpritesheetData).animations![animations[0]];
                            textures = textures.sort(
                                (a, b) => {
                                    const aKey = !!a.textureCacheIds?.length ? a.textureCacheIds[0] : undefined;
                                    if (!aKey) {
                                        return 1;
                                    }

                                    const bKey = !!b.textureCacheIds?.length ? b.textureCacheIds[0] : undefined;
                                    if (!bKey) {
                                        return -1;
                                    }

                                    return animationFrames.indexOf(aKey) - animationFrames.indexOf(bKey);
                                }
                            );
                        }

                        this.assets[key].textures = textures;
                    }
                    break;
                case PIXI.LoaderResource.TYPE.IMAGE:
                    this.assets[key].textures = [ resource.texture as PIXI.Texture ];
                    break;
                case PIXI.LoaderResource.TYPE.VIDEO:
                    const videoResource = new PIXI.VideoResource(resource.data, { autoPlay: false });
                    videoResource.source.loop = true;
                    videoResource.source.muted = true;
                    videoResource.source.playsInline = true;

                    this.assets[key].videoControl = videoResource.source;
                    this.assets[key].textures = [ PIXI.Texture.from(new PIXI.BaseTexture(videoResource), {}) ];
                    break;
            }

            this.assets[key].loaded = true;
        }

        this.resize(this.width, this.height);
    }

    protected addShape<K extends keyof TShapes>(name: K): this {
        const shape = this.shapes[name];
        if (!shape) {
            return this;
        }

        if (typeof (shape as PixiJSLayer<TConfig>).container !== "undefined") {
            this.container.addChild((shape as PixiJSLayer<TConfig>).container);
        } else {
            this.container.addChild(shape as PIXI.Container);
        }

        return this;
    }

    protected removeShape<K extends keyof TShapes>(name: K): this {
        const shape = this.shapes[name];
        if (!shape) {
            return this;
        }

        if (typeof (shape as PixiJSLayer<TConfig>).container !== "undefined") {
            this.container.removeChild((shape as PixiJSLayer<TConfig>).container);
        } else {
            this.container.removeChild(shape as PIXI.Container);
        }

        return this;
    }

    protected stateChanged(_props: TProps, _lastState: TProps["state"] | undefined): void {
        // ignore
    }

    protected calculateLayout(containerWidth: number, containerHeight: number, properties: PixiJSLayoutProperties): PixiJSBoundingBox {
        const isLandscape = containerWidth > containerHeight;
        properties = {
            ...properties,
            ...((isLandscape ? properties.landscapeOverride : properties.portraitOverride) ?? {}),
        };

        let width = (properties.width || 1920) + ((properties.paddingX ?? 0) * 2);
        let height = (properties.height || 1080) + ((properties.paddingY ?? 0) * 2);

        let factor = properties.fit === "cover" ?
            Math.max(containerWidth / width, containerHeight / height) :
            Math.min(containerWidth / width, containerHeight / height);

        if (properties.fit === "grow") {
            factor = Math.max(factor, 1);
        }

        if (properties.fit === "shrink") {
            factor = Math.min(factor, 1);
        }

        if (properties.maxScale != null) {
            factor = Math.min(factor, properties.maxScale);
        }

        if (properties.minScale != null) {
            factor = Math.max(factor, properties.minScale);
        }

        width = (properties.width || 1920) * factor;
        height = (properties.height || 1080) * factor;

        let x = (properties.offsetX ?? 0) * factor;
        if (properties.horizontalAlign === "left") {
            x += 0;
        } else if (properties.horizontalAlign === "right") {
            x += containerWidth - width;
        } else {
            x += (containerWidth - width) / 2;
        }

        let y = (properties.offsetY ?? 0) * factor;
        if (properties.verticalAlign === "top") {
            y += 0;
        } else if (properties.verticalAlign === "bottom") {
            y += containerHeight - height;
        } else {
            y += (containerHeight - height) / 2;
        }

        return {
            x,
            y,
            width,
            height,

            left: x,
            top: y,

            right: x + width,
            bottom: y + height,

            center: [ x + (width / 2), y + (height / 2) ],

            scale: factor,
        };
    }

    private onTick = (dt: number): void => {
        this.tick(dt);
    };
}
