import { BaseService, WsExceptionDto } from "../common";
import { SimpleEventDispatcher } from "strongly-typed-events";
import { WsBalanceUpdateDto } from "./dto/ws-balance-update.dto";
import _ from "lodash";


export class FinanceService extends BaseService {
    public readonly onBalanceUpdated = new SimpleEventDispatcher<WsBalanceUpdateDto>();
    public readonly onException = new SimpleEventDispatcher<WsExceptionDto>();
    private token?: string;

    public constructor(baseUrl?: string) {
        super(baseUrl);
        const onBalanceUpdatedThrottled = _.throttle(
            (dto: WsBalanceUpdateDto) => this.onBalanceUpdated.dispatch(dto),
            500,
            {
                trailing: true,
                leading: false,
            }
        );

        this.registerSocketEvent("exception", this.onException);
        this.registerSocketEvent<WsBalanceUpdateDto>("BalanceUpdated", (dto) => onBalanceUpdatedThrottled(dto));
    }

    public getToken(): string | undefined {
        return this.token;
    }

    public setToken(value: string): void {
        this.token = value;
    }
}
