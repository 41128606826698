export class LazyAsync<T> {
    private lastValue?: T;
    private currentPromise?: Promise<T>;
    private updating = false;
    private invoker: () => Promise<T>;

    public constructor(invoker: () => Promise<T>) {
        this.invoker = invoker;
    }

    public clear(): void {
        this.updating = false;
        this.lastValue = undefined;
    }

    public get value(): T | undefined {
        return this.lastValue;
    }

    public async get(): Promise<T> {
        if ((this.lastValue == null || this.currentPromise == null) && !this.updating) {
            this.currentPromise = this.action();
        }

        if (this.lastValue != null) {
            return this.lastValue;
        }

        return await this.currentPromise!;
    }

    private action(): Promise<T> {
        if (this.updating) {
            throw new Error("Invalid operation exception.");
        }

        this.updating = true;
        const promise = new Promise<T>(
            (resolve, reject) => {
                this.invoker().then(
                    (r) => {
                        if (!this.currentPromise || this.currentPromise === promise) {
                            this.lastValue = r;
                            this.updating = false;
                            resolve(this.lastValue);
                        } else {
                            resolve(this.currentPromise);
                        }
                    },
                    (c) => {
                        if (!this.currentPromise || this.currentPromise === promise) {
                            this.lastValue = undefined;
                            this.updating = false;
                            reject(c);
                        } else {
                            resolve(this.currentPromise);
                        }
                    }
                );
            }
        );

        return promise;
    }
}
