import * as React from "react";
import { Button } from "../button/button.component";
import { ChevronLeft, ChevronRight } from "react-feather";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { HomeProperties } from "./home.properties";
import { PreloadHelper } from "../../common/helpers/preload.helper";
import { Translate } from "../translate/translate";
import classNames from "classnames";
import styles from "./home.module.scss";

export class Home extends React.PureComponent<HomeProperties, {
    currentTab: number;
    isInTransition: boolean;
}> {
    private isComponentMounted = false;

    public constructor(props: HomeProperties) {
        super(props);
        this.state = {
            currentTab: 0,
            isInTransition: false,
        };

        if (props.background) {
            PreloadHelper.preload(props.background);
        }

        for (const slide of props.slides) {
            PreloadHelper.preload(slide.image);
        }
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;
        PreloadHelper.addEventListener(
            (p) => {
                if (!this.isComponentMounted || !p.completed) {
                    return;
                }
                this.forceUpdate();
            }
        );
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;
    }

    public render(): React.ReactNode {
        return <section
            className={
                classNames(
                    styles.home,
                    {
                        [styles.mobile]: this.props.isMobile,
                    }
                )
            }
            style={
                this.props.background ? { backgroundImage: `url("${PreloadHelper.get(this.props.background)?.local ?? ""}")` } : {}
            }
        >
            <main
                style={{ ["--tab" as any]: `${this.state.currentTab.toFixed(0)}` }}
            >
                <div>
                    {
                        this.props.slides.map(
                            (t, i) => <section
                                key={i} className={i === this.state.currentTab ? styles.active : undefined}
                                onClick={() => this.setTab(i)}
                            >
                                <img src={PreloadHelper.get(t.image)?.local} alt={t.title} />
                                <div>
                                    {
                                        !!t.title && <h2>{t.title}</h2>
                                    }
                                    {
                                        !!t.description && <span>{t.description}</span>
                                    }
                                </div>
                            </section>
                        )
                    }
                </div>
            </main>
            <footer>
                <Button
                    isDisabled={this.state.currentTab <= 0 || this.state.isInTransition}
                    onClick={() => this.setTab(this.state.currentTab - 1)}
                    style="game"
                >
                    <ChevronLeft />
                </Button>
                <Button
                    isDisabled={!this.props.isReady}
                    onClick={this.props.onEnter}
                    style="game"
                >
                    <SwitchTransition
                        mode="out-in"
                    >
                        <CSSTransition
                            classNames="fade"
                            timeout={300}
                            unmountOnExit={true}
                            key={!this.props.isReady ? "busy" : "done"}
                        >
                            <>
                                {!this.props.isReady && <span><Translate>CONNECTING...</Translate></span>}
                                {!!this.props.isReady && <span><Translate>LET&apos;S GO</Translate></span>}
                            </>
                        </CSSTransition>
                    </SwitchTransition>
                </Button>
                <Button
                    isDisabled={this.state.currentTab >= (this.props.slides.length - 1) || this.state.isInTransition}
                    onClick={() => this.setTab(this.state.currentTab + 1)}
                    style="game"
                >
                    <ChevronRight />
                </Button>
            </footer>
        </section>;
    }

    private setTab(index: number) {
        if (this.state.isInTransition) {
            return;
        }

        this.setState(
            {
                currentTab: index,
                isInTransition: true,
            },
            () => {
                setTimeout(
                    () => {
                        if (!this.isComponentMounted) {
                            return;
                        }

                        this.setState(
                            {
                                currentTab: index,
                                isInTransition: false,
                            }
                        );
                    },
                    500
                );
            }
        );
    }
}
