import * as React from "react";
import { AutoPlayConfigurations, AutoPlayModalProperties } from "./auto-play-modal.properties";
import { Button } from "../button/button.component";
import { Checkbox } from "../checkbox/checkbox.component";
import { Inputbox } from "../inputbox/inputbox.component";
import { Translate } from "../translate/translate";
import { XCircle } from "react-feather";
import classNames from "classnames";
import styles from "./auto-play-modal.module.scss";

const DefaultAutoPlayConfiguration: AutoPlayConfigurations = {
    shouldStopWhenBoardChanges: false,
    shouldStopWhenPickEmHappened: false,
    stopOnMaximumBalance: undefined,
    stopOnMinimumBalance: undefined,
    stopOnWinning: undefined,
};

export class AutoPlayModal extends React.PureComponent<AutoPlayModalProperties, {
    config: AutoPlayConfigurations;
    rounds?: number;
}> {
    public constructor(props: AutoPlayModalProperties) {
        super(props);
        this.state = {
            config: {
                ...(props.configurations ?? DefaultAutoPlayConfiguration),
                stopOnMaximumBalance: props.configurations?.stopOnMaximumBalance == null ? undefined : (Math.max(props.configurations.stopOnMaximumBalance - (props.balance ?? 0), 0) || undefined),
                stopOnMinimumBalance: props.configurations?.stopOnMinimumBalance == null ? undefined : (Math.max((props.balance ?? 0) - props.configurations.stopOnMinimumBalance, 0) || undefined),
            },
            rounds: props.autoRounds,
        };
    }

    public componentDidUpdate(oldProps: AutoPlayModalProperties): void {
        if (oldProps.configurations !== this.props.configurations) {
            this.setState(
                {
                    config: {
                        ...(this.props.configurations ?? DefaultAutoPlayConfiguration),
                        stopOnMaximumBalance: this.props.configurations?.stopOnMaximumBalance == null ? undefined : (
                            Math.max(this.props.configurations.stopOnMaximumBalance - (this.props.balance ?? 0), 0) || undefined
                        ),
                        stopOnMinimumBalance: this.props.configurations?.stopOnMinimumBalance == null ? undefined : (
                            Math.max((this.props.balance ?? 0) - this.props.configurations.stopOnMinimumBalance, 0) || undefined
                        ),
                    },
                    rounds: this.props.autoRounds,
                }
            );
        }
    }

    public render(): React.ReactNode {
        return <article
            className={
                classNames(
                    styles.modal,
                    {
                        [styles.mobile]: this.props.isMobile,
                    }
                )
            }
        >
            <header>
                <h1><Translate>AUTOPLAY</Translate></h1>
                <Button
                    onClick={this.onClose}
                    style="game"
                >
                    {!this.props.isMobile && <Translate>CLOSE</Translate>}
                    {!!this.props.isMobile && <XCircle />}
                </Button>
            </header>
            <main>
                <section>
                    <Checkbox
                        isActive={this.state.config.stopOnWinning === 0}
                        onToggle={this.onStopOnWinToggled}
                    >
                        <Translate>Stop on any win</Translate>
                    </Checkbox>
                </section>
                <section>
                    <Checkbox
                        isActive={this.state.config.shouldStopWhenBoardChanges}
                        onToggle={this.onStopOnBoardChangeToggled}
                    >
                        <Translate>Stop if Free Spins are won</Translate>
                    </Checkbox>
                </section>
                <section>
                    <Checkbox
                        isActive={this.state.config.shouldStopWhenPickEmHappened}
                        onToggle={this.onStopOnPickEmToggled}
                    >
                        <Translate>Stop if Pick Bonus is won</Translate>
                    </Checkbox>
                </section>
                <section onClick={!this.state.config.stopOnWinning ? this.onStopOnWinAmountToggled : undefined}>
                    <Checkbox
                        isActive={!!this.state.config.stopOnWinning}
                        onToggle={!!this.state.config.stopOnWinning ? this.onStopOnWinAmountToggled : undefined}
                    >
                        <Translate>Stop if single win exceeds</Translate>
                    </Checkbox>
                    <Inputbox
                        isDisabled={!this.state.config.stopOnWinning}
                        value={!!this.state.config.stopOnWinning ? this.state.config.stopOnWinning?.toFixed(2) : undefined}
                        onChanged={this.onStopOnWinAmountChanged}
                    />
                </section>
                <section onClick={!this.state.config.stopOnMaximumBalance ? this.onStopOnBalanceIncreaseAmountToggled : undefined}>
                    <Checkbox
                        isActive={!!this.state.config.stopOnMaximumBalance}
                        onToggle={!!this.state.config.stopOnMaximumBalance ? this.onStopOnBalanceIncreaseAmountToggled : undefined}
                    >
                        <Translate>Stop if balance increases by</Translate>
                    </Checkbox>
                    <Inputbox
                        isDisabled={!this.state.config.stopOnMaximumBalance}
                        value={this.state.config.stopOnMaximumBalance?.toFixed(2)}
                        onChanged={this.onStopOnBalanceIncreaseAmountChanged}
                    />
                </section>
                <section onClick={!this.state.config.stopOnMinimumBalance ? this.onStopOnBalanceDecreaseAmountToggled : undefined}>
                    <Checkbox
                        isActive={!!this.state.config.stopOnMinimumBalance}
                        onToggle={!!this.state.config.stopOnMinimumBalance ? this.onStopOnBalanceDecreaseAmountToggled : undefined}
                    >
                        <Translate>Stop if balance decreases by</Translate>
                    </Checkbox>
                    <Inputbox
                        isDisabled={!this.state.config.stopOnMinimumBalance}
                        value={this.state.config.stopOnMinimumBalance?.toFixed(2)}
                        onChanged={this.onStopOnBalanceDecreaseAmountChanged}
                    />
                </section>
                <section>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 0 && this.state.rounds <= 10}
                        onClick={() => this.onRoundsChanged(10)}
                        style="form"
                    ><Translate>10</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 10 && this.state.rounds <= 25}
                        onClick={() => this.onRoundsChanged(25)}
                        style="form"
                    ><Translate>25</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 25 && this.state.rounds <= 50}
                        onClick={() => this.onRoundsChanged(50)}
                        style="form"
                    ><Translate>50</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 50 && this.state.rounds <= 100}
                        onClick={() => this.onRoundsChanged(100)}
                        style="form"
                    ><Translate>100</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 100 && this.state.rounds <= 250}
                        onClick={() => this.onRoundsChanged(250)}
                        style="form"
                    ><Translate>250</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 250 && this.state.rounds <= 500}
                        onClick={() => this.onRoundsChanged(500)}
                        style="form"
                    ><Translate>500</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 500 && this.state.rounds <= 750}
                        onClick={() => this.onRoundsChanged(750)}
                        style="form"
                    ><Translate>750</Translate></Button>
                    <Button
                        isActive={!!this.state.rounds && this.state.rounds > 750 && this.state.rounds <= 1000}
                        onClick={() => this.onRoundsChanged(1000)}
                        style="form"
                    ><Translate>1000</Translate></Button>
                </section>
                <footer>
                    <Button
                        onClick={this.onConfirm}
                        style="game"
                        isDisabled={!this.state.rounds}
                    ><Translate>CONFIRM</Translate></Button>
                    <Button
                        onClick={this.onReset}
                        isDisabled={this.isEmpty()}
                        style="game"
                    ><Translate>RESET</Translate></Button>
                </footer>
            </main>
        </article>;
    }

    private isEmpty() {
        return !this.state.rounds && Object.entries(DefaultAutoPlayConfiguration).every(([ key, value ]) => this.state.config[key as keyof AutoPlayConfigurations] === value);
    }

    private onClose = () => {
        this.setState(
            {
                config: { ...DefaultAutoPlayConfiguration },
                rounds: undefined,
            }
        );

        if (!this.props.onExit) {
            return;
        }

        this.props.onExit();
    };

    private onConfirm = () => {
        if (!this.props.onUpdate) {
            return;
        }

        if (!!this.state.rounds) {
            this.props.onUpdate(
                {
                    ...this.state.config,
                    stopOnMaximumBalance: this.state.config.stopOnMaximumBalance == null ? undefined : ((this.props.balance ?? 0) + this.state.config.stopOnMaximumBalance),
                    stopOnMinimumBalance: this.state.config.stopOnMinimumBalance == null ? undefined : ((this.props.balance ?? 0) - this.state.config.stopOnMinimumBalance),
                },
                this.state.rounds
            );
        }

        this.onClose();
    };

    private onReset = () => {
        this.setState(
            {
                config: {
                    ...DefaultAutoPlayConfiguration,
                },
                rounds: undefined,
            }
        );
    };

    private onRoundsChanged(rounds: number) {
        this.setState(
            {
                rounds,
            }
        );
    }

    private onStopOnWinToggled = () => {
        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnWinning: this.state.config.stopOnWinning !== 0 ? 0 : undefined,
                },
            }
        );
    };

    private onStopOnBoardChangeToggled = () => {
        this.setState(
            {
                config: {
                    ...this.state.config,
                    shouldStopWhenBoardChanges: !this.state.config.shouldStopWhenBoardChanges,
                },
            }
        );
    };

    private onStopOnPickEmToggled = () => {
        this.setState(
            {
                config: {
                    ...this.state.config,
                    shouldStopWhenPickEmHappened: !this.state.config.shouldStopWhenPickEmHappened,
                },
            }
        );
    };

    private onStopOnWinAmountToggled = (e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnWinning: !this.state.config.stopOnWinning ? 100 : undefined,
                },
            }
        );
    };

    private onStopOnWinAmountChanged = (amount: string) => {
        let value = parseFloat(amount);
        if (!isFinite(value) || isNaN(value) || value < 0.01 || value > 1000000) {
            return false;
        }
        value = Math.round(value * 100) / 100;

        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnWinning: value,
                },
            }
        );

        return true;
    };

    private onStopOnBalanceIncreaseAmountToggled = (e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnMaximumBalance: !this.state.config.stopOnMaximumBalance ? 100 : undefined,
                },
            }
        );
    };

    private onStopOnBalanceIncreaseAmountChanged = (amount: string) => {
        let value = parseFloat(amount);
        if (!isFinite(value) || isNaN(value) || value < 0.01 || value > 1000000) {
            return false;
        }
        value = Math.round(value * 100) / 100;

        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnMaximumBalance: value,
                },
            }
        );

        return true;
    };

    private onStopOnBalanceDecreaseAmountToggled = (e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnMinimumBalance: !this.state.config.stopOnMinimumBalance ? 100 : undefined,
                },
            }
        );
    };

    private onStopOnBalanceDecreaseAmountChanged = (amount: string) => {
        let value = parseFloat(amount);
        if (!isFinite(value) || isNaN(value) || value < 0.01 || value > 1000000) {
            return false;
        }
        value = Math.round(value * 100) / 100;

        this.setState(
            {
                config: {
                    ...this.state.config,
                    stopOnMinimumBalance: value,
                },
            }
        );

        return true;
    };
}
