import "./index.style.scss";
import "modern-normalize/modern-normalize.css";
import { FinanceService, SessionService } from "@tgg/shared/src";
import { SlotsService } from "./services/slots/slots.service";
import App from "./app";
import React from "react";
import ReactDOM from "react-dom";

const baseUri = process.env.SLOTS_SERVER || "";
const slotsService = new SlotsService(`${baseUri}/api`);
const sessionService = new SessionService(`${baseUri}/api`);
const financeService = new FinanceService(`${baseUri}/hubs/finance`);

ReactDOM.render(
    <React.StrictMode>
        <App
            sessionService={sessionService}
            slotsService={slotsService}
            financeService={financeService}
        />
    </React.StrictMode>,
    document.getElementById("root")
);
