import { GameReel } from "../../components/game/game.properties";
import {
    getRattleSnakeGame,
    RattleSnakeGameBoard,
    RattleSnakeGameBonusReelSymbols,
    RattleSnakeGameMainReelSymbols,
    RattleSnakeGameNumberOfReels,
    RattleSnakeGameOverlay,
    RattleSnakeGameSymbol
} from "../../components/game-page/rattle-snake-game.constants";
import { SlotsActionDto } from "./dtos/slots-action.dto";
import { SlotsActionType } from "./enums/slots-action-type.enum";
import { SlotsBalanceActionDto } from "./dtos/slots-balance-action.dto";
import { SlotsBalanceActionType } from "./enums/slots-balance-action-type.enum";
import { SlotsBoardActionDto } from "./dtos/slots-board-action.dto";
import { SlotsGameDto } from "./dtos/slots-game.dto";
import { SlotsOverlayActionDto } from "./dtos/slots-overlay-action.dto";
import { SlotsPickActionDto } from "./dtos/slots-pick-action.dto";
import { SlotsPickActionReelDto } from "./dtos/slots-pick-action-reel.dto";
import { SlotsPickDto } from "./dtos/slots-pick.dto";
import { SlotsServiceBase } from "./slots-service.base";
import { SlotsSpinActionDto } from "./dtos/slots-spin-action.dto";
import { SlotsSpinActionPaylineDto } from "./dtos/slots-spin-action-payline.dto";
import { SlotsSpinActionReelDto } from "./dtos/slots-spin-action-reel.dto";
import { SlotsSpinDto } from "./dtos/slots-spin.dto";
import _ from "lodash";

type PayLine = [number, number, number, number, number];

const PayTable: {
    [K in RattleSnakeGameSymbol]: [number, number, number, number, number]
} = {
    W: [ 0, 0, 0, 0, 0 ],
    H1: [ 0, 5, 30, 90, 600 ],
    H2: [ 0, 0, 25, 75, 500 ],
    H3: [ 0, 0, 20, 60, 400 ],
    H4: [ 0, 0, 15, 45, 300 ],
    N1: [ 0, 0, 10, 30, 200 ],
    N2: [ 0, 0, 10, 30, 200 ],
    N3: [ 0, 0, 5, 15, 100 ],
    N4: [ 0, 0, 5, 15, 100 ],
    N5: [ 0, 0, 5, 15, 100 ],
    F: [ 0, 0, 2, 5, 50 ],
    B: [ 0, 0, 2, 5, 50 ],
    // B2: [0, 0, 0, 0, 0],
};

const PayLines: PayLine[] = [
    [ 0, 0, 0, 0, 0 ], // straight line
    [ 1, 1, 1, 1, 1 ], // straight line
    [ 2, 2, 2, 2, 2 ], // straight line
    [ 0, 1, 2, 1, 0 ], // reverse head
    [ 2, 1, 0, 1, 2 ], // head
    [ 0, 0, 1, 2, 2 ],
    [ 2, 2, 1, 0, 0 ],
    [ 1, 0, 0, 0, 1 ],
    [ 1, 2, 2, 2, 1 ],
    [ 0, 1, 1, 1, 2 ],
    [ 2, 1, 1, 1, 0 ],
    [ 0, 2, 2, 2, 0 ],
    [ 2, 0, 0, 0, 2 ],
    [ 1, 0, 2, 0, 1 ],
    [ 1, 2, 0, 2, 1 ],
];

interface PickEmChance {
    chance: number | null;
    score: number | null;
}

const PickemTable: {
    [key: number]: {
        scores: PickEmChance[];
        picks: number;
    };
} = {
    3: {
        scores: [
            {
                score: null,
                chance: 3,
            },
            {
                score: 5,
                chance: 3,
            },
            {
                score: 10,
                chance: 1,
            },
            {
                score: 20,
                chance: 1,
            },
        ],
        picks: 1,
    },
    4: {
        scores: [
            {
                score: null,
                chance: 6,
            },
            {
                score: 5,
                chance: 1,
            },
            {
                score: 10,
                chance: 2,
            },
            {
                score: 20,
                chance: 3,
            },
        ],
        picks: 1,
    },
    5: {
        scores: [
            {
                score: null,
                chance: null,
            },
            {
                score: 5,
                chance: 1,
            },
            {
                score: 10,
                chance: 2,
            },
            {
                score: 20,
                chance: 3,
            },
        ],
        picks: 2,
    },
};

const Wild: RattleSnakeGameSymbol = "W";

const FreeSpinScattersMin = 3;
const FreeSpinScattersMax = 5;
const FreeSpinScatter: RattleSnakeGameSymbol = "F";
const FreeSpinIntrupter: RattleSnakeGameSymbol = "B";

const PickemScattersMin = 3;
const PickemScattersMax = 5;
const PickemScatter: RattleSnakeGameSymbol = "B";

const BigWinScoreThreshold = 50;
const BigWinWinningThreshold = 5;

const Presets: {
    [key: string]: [string, number[]];
} = {
    n1: [ "N1 - 2xPay", [ 9, 11, 14, 0, 10 ] ],
    n2: [ "N2", [ 7, 7, 10, 29, 20 ] ],
    n3: [ "N3", [ 0, 0, 8, 4, 0 ] ],
    n4: [ "N4", [ 3, 2, 1, 14, 8 ] ],
    h1: [ "H1 - 6xPay", [ 21, 0, 31, 30, 32 ] ],
    h2: [ "H2 - BWin", [ 30, 14, 4, 2, 4 ] ],
    h3: [ "H3", [ 4, 13, 3, 6, 0 ] ],
    h4: [ "H4", [ 5, 4, 5, 7, 16 ] ],
    w: [ "W - BWin", [ 21, 8, 16, 20, 22 ] ],
    f: [ "F - FSpin", [ 39, 39, 58, 28, 29 ] ],
    b: [ "B - Pick5", [ 18, 19, 14, 18, 39 ] ],
    anticipation: [ "Anti", [ 39, 39, 39, 39, 57 ] ],
    pickem3: [ "Pick3", [ 59, 58, 17, 59, 59 ] ],
    pickem4: [ "Pick4", [ 59, 58, 15, 59, 59 ] ],
};

interface PaylineScore {
    payline: [number, number, number, number, number];
    symbol: RattleSnakeGameSymbol;
    score: number;
    matches: number;
}

interface PickEm {
    rules: PickEmChance[];
    positions: Array<[number, number]>;
    remainingPicks: number;
}

export class RattleSnakeSlotsSimulatorService implements SlotsServiceBase {
    private balance = 10000;
    private currentGameId?: number;
    private currentPickEm?: PickEm;
    private hasFreeSpin = false;
    private lastIndexes?: number[];
    private currentAmount?: number;

    public getPresets(): Record<string, string> {
        return Object.fromEntries(Object.entries(Presets).map((p) => [ p[0], p[1][0] ]));
    }

    public async pick(args: SlotsPickDto): Promise<SlotsGameDto<RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay>> {
        let response: SlotsGameDto<RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay> | undefined | string;

        try {
            if (!this.currentGameId) {
                throw new Error("This game has already been ended");
            }

            if (args.matchId !== this.currentGameId) {
                throw new Error("Another game is already running for this user.");
            }

            if (!this.currentPickEm || this.currentPickEm.remainingPicks <= 0 || !this.lastIndexes || this.currentAmount == null) {
                throw new Error("This game is not in the pickem state.");
            }

            const position = this.currentPickEm.positions.find((p) => p[0] === args.reel && p[1] === (args.index - this.lastIndexes![args.reel]));
            if (!position) {
                throw new Error("Cant pick this item.");
            }

            let rule: PickEmChance | undefined;
            const forcedRules = this.currentPickEm.rules.filter((r) => r.chance == null);
            if (forcedRules.length === this.currentPickEm.remainingPicks) {
                rule = forcedRules[0];
                this.currentPickEm.rules = this.currentPickEm.rules.filter((r) => r !== rule);
            } else {
                const chanceRules = this.currentPickEm.rules.filter((r) => r.chance != null);
                let totalChance = chanceRules.reduce((a, b) => a + b.chance!, 0);
                const random = Math.trunc(Math.random() * totalChance);
                for (const chanceRule of chanceRules) {
                    totalChance -= chanceRule.chance!;
                    if (random < totalChance) {
                        continue;
                    }

                    rule = chanceRule;
                    break;
                }
            }

            if (!rule) {
                throw new Error("A rule is not found for this pick.");
            }

            this.currentPickEm.positions = this.currentPickEm.positions.filter((p) => p !== position);
            this.currentPickEm.remainingPicks--;

            const actions: Array<SlotsActionDto<SlotsActionType, RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay>> = [];

            if (rule.score === null) {
                this.hasFreeSpin = true;
            } else {
                const winning = this.currentAmount * PayLines.length * rule.score;
                const isBigWin = rule.score >= BigWinScoreThreshold && winning >= BigWinWinningThreshold;
                this.balance += winning;

                actions.push(
                    {
                        type: SlotsActionType.Overlay,
                        payload: {
                            overlay: isBigWin ? "big-win" : "win",
                            bindings: {
                                score: rule.score.toFixed(0) + "x",
                                winnings: winning,
                                balance: this.balance,
                            },
                        } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
                    },
                    {
                        type: SlotsActionType.Balance,
                        payload: {
                            type: isBigWin ? SlotsBalanceActionType.BigWin : SlotsBalanceActionType.Win,
                            winnings: winning,
                            balance: this.balance,
                        } as SlotsBalanceActionDto,
                    }
                    // {
                    //     type: SlotsActionType.Delay,
                    //     payload: {
                    //         delay: 1,
                    //     } as SlotsDelayActionDto,
                    // }
                );
            }

            if (this.currentPickEm.remainingPicks > 0) {
                actions.push(
                    {
                        type: SlotsActionType.Pick,
                        payload: {
                            reels: this.lastIndexes.map(
                                (r, reelIndex) => (
                                    {
                                        picks: this.currentPickEm?.positions.filter((p) => p[0] === reelIndex).map((p) => p[1] + r),
                                    } as SlotsPickActionReelDto
                                )
                            ),
                            score: rule.score ?? 0,
                        } as SlotsPickActionDto,
                    }
                );
            } else {
                actions.push(
                    {
                        type: SlotsActionType.Pick,
                        payload: {
                            reels: this.lastIndexes.map(
                                () => (
                                    {
                                        picks: [],
                                    } as SlotsPickActionReelDto
                                )
                            ),
                            score: rule.score ?? 0,
                        } as SlotsPickActionDto,
                    }
                );

                if (this.hasFreeSpin) {
                    actions.push(
                        ...this.getFreeSpinActions(this.currentAmount)
                    );
                }
            }

            const isFinished = this.currentPickEm.remainingPicks <= 0;
            response = {
                actions,
                matchId: this.currentGameId,
                isFinished,
                finalBalance: this.balance,
            };

            if (isFinished) {
                this.currentGameId = undefined;
                this.hasFreeSpin = false;
                this.currentPickEm = undefined;
                this.lastIndexes = undefined;
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                response = error;
            } else if (error instanceof Error) {
                response = error.message;
            } else {
                response = "General failure to play the game.";
            }
        }

        if (!response) {
            throw new Error("Simulation failed!!");
        }

        // eslint-disable-next-line no-console
        console.log(response);

        // simulate network latency
        return await new Promise(
            (resolve, reject) => {
                setTimeout(
                    () => {
                        if (typeof response === "string") {
                            reject(response);
                        } else if (response) {
                            resolve(response);
                        }
                    },
                    (Math.random() + 0.3) * 0.2
                );
            }
        );
    }

    public async spin(args: SlotsSpinDto): Promise<SlotsGameDto<RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay>> {
        let response: SlotsGameDto<RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay> | undefined | string;

        try {
            // validation
            if (args.amount <= 0) {
                throw new Error("Invalid value provided for amount");
            }

            if (args.amount > this.balance) {
                throw new Error("Insufficient balance.");
            }

            if (!!this.currentGameId) {
                throw new Error("Another game is already running for this user.");
            }

            // update user's balance
            this.balance -= args.amount * getRattleSnakeGame("en").paylines;
            this.currentAmount = args.amount;

            this.currentGameId = this.currentGameId ?? Math.trunc(Math.random() * 1000000);
            const actions: Array<SlotsActionDto<SlotsActionType, RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay>> = [
                {
                    type: SlotsActionType.Balance,
                    payload: {
                        balance: this.balance,
                    } as SlotsBalanceActionDto,
                },
            ];
            let isFinished = true;

            const mainReelsSymbols = Object.keys(RattleSnakeGameMainReelSymbols).map((k) => RattleSnakeGameMainReelSymbols[k as GameReel<RattleSnakeGameNumberOfReels>]);
            this.lastIndexes = typeof Presets[args.preset ?? ""] !== "undefined" ? Presets[args.preset!][1] : this.getSpin(mainReelsSymbols);
            const symbols = this.getSymbols(mainReelsSymbols, this.lastIndexes);
            const paylines = this.getPaylines(symbols);
            const picks = this.getPickems(symbols);

            actions.push(
                {
                    type: SlotsActionType.Spin,
                    payload: {
                        board: "main",
                        reels: this.lastIndexes.map(
                            (r, reelIndex) => (
                                {
                                    index: r,
                                    picks: [],
                                    attentions: symbols[reelIndex].map((s, i) => (s === FreeSpinScatter || s === PickemScatter) ? i : -1).filter((i) => i >= 0).map((i) => i + r),
                                } as SlotsSpinActionReelDto
                            )
                        ),
                        payLines: paylines.map(
                            (p): SlotsSpinActionPaylineDto<RattleSnakeGameSymbol> => (
                                {
                                    indexes: p.payline,
                                    symbol: p.symbol,
                                    matches: p.matches,
                                    amount: args.amount,
                                    score: p.score,
                                }
                            )
                        ),
                        scatters: [ FreeSpinScatter, PickemScatter ].map(
                            (symbol) => {
                                const indexes = symbols.map((reel) => reel.map((s, i) => s === symbol ? i : -1).filter((i) => i >= 0));
                                return {
                                    symbol,
                                    indexes,
                                    matches: indexes.reduce((a, b) => a + b.length, 0),
                                };
                            }
                        ),
                        scatterSymbols: [ PickemScatter, FreeSpinScatter ],
                        wildSymbols: [ Wild ],
                        anticipationMaxSymbols: Math.max(PickemScattersMax, FreeSpinScattersMax),
                        anticipationMinSymbols: Math.min(PickemScattersMin, FreeSpinScattersMin),
                    } as SlotsSpinActionDto<RattleSnakeGameSymbol, RattleSnakeGameBoard>,
                }
            );

            if (!!paylines.length) {
                const paylineScores = paylines.reduce((a, b) => a + b.score, 0);
                const paylineWinnings = args.amount * paylineScores;
                const isBigWin = paylineScores >= BigWinScoreThreshold && paylineWinnings >= BigWinWinningThreshold;
                this.balance += paylineWinnings;

                actions.push(
                    {
                        type: SlotsActionType.Overlay,
                        payload: {
                            overlay: isBigWin ? "big-win" : "win",
                            bindings: {
                                score: paylineScores.toFixed(0) + "x",
                                winnings: paylineWinnings,
                                balance: this.balance,
                            },
                        } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
                    },
                    {
                        type: SlotsActionType.Balance,
                        payload: {
                            type: isBigWin ? SlotsBalanceActionType.BigWin : SlotsBalanceActionType.Win,
                            winnings: paylineWinnings,
                            balance: this.balance,
                        } as SlotsBalanceActionDto,
                    }
                );
            }

            const bonus = this.getFreeSpin(symbols);
            if (!!bonus.length) {
                if (!!picks) {
                    this.hasFreeSpin = true;
                } else {
                    actions.push(...this.getFreeSpinActions(args.amount));
                }
            }

            if (!!picks) {
                this.currentPickEm = picks;
                isFinished = false;

                actions.push(
                    {
                        type: SlotsActionType.Overlay,
                        payload: {
                            overlay: "pickem",
                        } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
                    }
                );

                actions.push(
                    {
                        type: SlotsActionType.Pick,
                        payload: {
                            reels: this.lastIndexes.map(
                                (r, reelIndex) => (
                                    {
                                        picks: this.currentPickEm?.positions.filter((p) => p[0] === reelIndex).map((p) => p[1] + r),
                                    } as SlotsPickActionReelDto
                                )
                            ),
                            score: 0,
                        } as SlotsPickActionDto,
                    }
                );
            }

            response = {
                matchId: this.currentGameId,
                isFinished,
                actions,
                finalBalance: this.balance,
            };

            if (isFinished) {
                this.currentGameId = undefined;
                this.hasFreeSpin = false;
                this.currentPickEm = undefined;
                this.lastIndexes = undefined;
            }
        } catch (error: unknown) {
            if (typeof error === "string") {
                response = error;
            } else if (error instanceof Error) {
                response = error.message;
            } else {
                response = "General failure to play the game.";
            }
        }

        if (!response) {
            throw new Error("Simulation failed!!");
        }

        // eslint-disable-next-line no-console
        console.log(response);

        // simulate network latency
        return await new Promise(
            (resolve, reject) => {
                setTimeout(
                    () => {
                        if (typeof response === "string") {
                            reject(response);
                        } else if (response) {
                            resolve(response);
                        }
                    },
                    (Math.random() + 0.3) * 0.2
                );
            }
        );
    }

    public getBalance(): number {
        return this.balance;
    }

    private getFreeSpinActions(amount: number): Array<SlotsActionDto<SlotsActionType, RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay>> {
        const actions: Array<SlotsActionDto<SlotsActionType, RattleSnakeGameSymbol, RattleSnakeGameBoard, RattleSnakeGameOverlay>> = [
            {
                type: SlotsActionType.Overlay,
                payload: {
                    overlay: "free-spin-enter",
                } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
            },
            {
                type: SlotsActionType.Board,
                payload: {
                    board: "bonus",
                    scatterSymbols: [],
                    wildSymbols: [ Wild ],
                    anticipationMaxSymbols: 0,
                    anticipationMinSymbols: 0,
                } as SlotsBoardActionDto<RattleSnakeGameSymbol, RattleSnakeGameBoard>,
            },
        ];

        const bonusReelsSymbols = Object.keys(RattleSnakeGameBonusReelSymbols).map((k) => RattleSnakeGameBonusReelSymbols[k as GameReel<RattleSnakeGameNumberOfReels>]);
        let totalScore = 0;
        while (true) {
            const indexes = this.getSpin(bonusReelsSymbols);
            const symbols = this.getSymbols(bonusReelsSymbols, indexes);
            const paylines = this.getPaylines(symbols);

            actions.push(
                {
                    type: SlotsActionType.Spin,
                    payload: {
                        board: "bonus",
                        reels: indexes.map(
                            (r, reelIndex) => (
                                {
                                    index: r,
                                    picks: [],
                                    attentions: symbols[reelIndex].map((s, i) => (s === FreeSpinIntrupter) ? i : -1).filter((i) => i >= 0).map((i) => i + r),
                                } as SlotsSpinActionReelDto
                            )
                        ),
                        payLines: paylines.map(
                            (p): SlotsSpinActionPaylineDto<RattleSnakeGameSymbol> => (
                                {
                                    indexes: p.payline,
                                    symbol: p.symbol,
                                    matches: p.matches,
                                    amount,
                                    score: p.score,
                                }
                            )
                        ),

                        scatters: [],
                        scatterSymbols: [],
                        wildSymbols: [ Wild ],
                        anticipationMaxSymbols: 0,
                        anticipationMinSymbols: 0,
                        amount,
                    } as SlotsSpinActionDto<RattleSnakeGameSymbol, RattleSnakeGameBoard>,
                }
            );

            if (!!paylines.length) {
                const paylineScores = paylines.reduce((a, b) => a + b.score, 0);
                const paylineWinnings = amount * paylineScores;
                const isBigWin = paylineScores >= BigWinScoreThreshold && paylineWinnings >= BigWinWinningThreshold;
                this.balance += paylineWinnings;

                actions.push(
                    {
                        type: SlotsActionType.Overlay,
                        payload: {
                            overlay: isBigWin ? "big-win" : "win",
                            bindings: {
                                score: paylineScores.toFixed(0) + "x",
                                winnings: paylineWinnings,
                                balance: this.balance,
                            },
                        } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
                    },
                    {
                        type: SlotsActionType.Balance,
                        payload: {
                            type: isBigWin ? SlotsBalanceActionType.BigWin : SlotsBalanceActionType.Win,
                            winnings: paylineWinnings,
                            balance: this.balance,
                        } as SlotsBalanceActionDto,
                    }
                );

                totalScore += paylineScores;
            }

            if (symbols[symbols.length - 1].some((s) => s === FreeSpinIntrupter)) {
                break;
            }
        }

        if (totalScore > 0) {
            actions.push(
                {
                    type: SlotsActionType.Overlay,
                    payload: {
                        overlay: "free-spin-stats",
                        bindings: {
                            score: totalScore,
                            winnings: totalScore * amount,
                            balance: this.balance,
                        },
                    } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
                }
            );
        } else {
            actions.push(
                {
                    type: SlotsActionType.Overlay,
                    payload: {
                        overlay: "free-spin-exit",
                        bindings: {
                            score: totalScore,
                            winnings: totalScore * amount,
                            balance: this.balance,
                        },
                    } as SlotsOverlayActionDto<RattleSnakeGameOverlay>,
                }
            );
        }

        actions.push(

            {
                type: SlotsActionType.Board,
                payload: {
                    board: "main",
                    scatterSymbols: [ PickemScatter, FreeSpinScatter ],
                    wildSymbols: [ Wild ],
                    anticipationMaxSymbols: Math.max(PickemScattersMax, FreeSpinScattersMax),
                    anticipationMinSymbols: Math.min(PickemScattersMin, FreeSpinScattersMin),
                } as SlotsBoardActionDto<RattleSnakeGameSymbol, RattleSnakeGameBoard>,
            }
        );

        return actions;
    }

    private getPickems(results: Array<[RattleSnakeGameSymbol, RattleSnakeGameSymbol, RattleSnakeGameSymbol]>): PickEm | undefined {
        const pickLines = this.getPickem(results);
        const count = pickLines.reduce((a, l) => a + l.length, 0);
        if (typeof PickemTable[count] === "undefined") {
            return undefined;
        }

        const positions: Array<[number, number]> = [];
        for (let reel = 0; reel < results.length; reel++) {
            for (let row = 0; row < results[reel].length; row++) {
                if (results[reel][row] !== PickemScatter) {
                    continue;
                }

                positions.push([ reel, row ]);
            }
        }

        return {
            positions,
            rules: PickemTable[count].scores,
            remainingPicks: PickemTable[count].picks,
        };
    }

    private getSpin(reels: RattleSnakeGameSymbol[][]): number[] {
        const resultReelIndexes: number[] = [];
        for (const reel of reels) {
            const index = Math.floor(Math.random() * reel.length);
            resultReelIndexes.push(index);
        }

        return resultReelIndexes;
    }

    private getSymbols(reels: RattleSnakeGameSymbol[][], indexes: number[]): Array<[RattleSnakeGameSymbol, RattleSnakeGameSymbol, RattleSnakeGameSymbol]> {
        const symbols: RattleSnakeGameSymbol[][] = [];
        for (let i = 0; i < indexes.length; i++) {
            const reelSymbols: RattleSnakeGameSymbol[] = [];
            const index = indexes[i];
            for (let w = 0; w < 3; w++) {
                reelSymbols.push(reels[i][(index + w) % reels[i].length]);
            }
            symbols.push(reelSymbols);
        }

        return symbols as Array<[RattleSnakeGameSymbol, RattleSnakeGameSymbol, RattleSnakeGameSymbol]>;
    }

    private getFreeSpin(results: Array<[RattleSnakeGameSymbol, RattleSnakeGameSymbol, RattleSnakeGameSymbol]>): number[][] {
        const scatters = results.reduce((s, result) => s + result.reduce((a, b) => a + (b === FreeSpinScatter ? 1 : 0), 0), 0);
        if (scatters >= FreeSpinScattersMin && scatters <= FreeSpinScattersMax) {
            return results.map((result) => result.map((r, i) => r === FreeSpinScatter ? i : -1).filter((i) => i >= 0));
        }

        return [];
    }

    private getPickem(results: Array<[RattleSnakeGameSymbol, RattleSnakeGameSymbol, RattleSnakeGameSymbol]>): number[][] {
        const scatters = results.reduce((s, result) => s + result.reduce((a, b) => a + (b === PickemScatter ? 1 : 0), 0), 0);
        if (scatters >= PickemScattersMin && scatters <= PickemScattersMax) {
            return results.map((result) => result.map((r, i) => r === PickemScatter ? i : -1).filter((i) => i >= 0));
        }

        return [];
    }

    private getPaylines(results: Array<[RattleSnakeGameSymbol, RattleSnakeGameSymbol, RattleSnakeGameSymbol]>): PaylineScore[] {
        const paylineSymbols: PaylineScore[] = [];
        for (const payline of PayLines) {
            const lineSymbols = results.map((r, i) => r[payline[i]]);
            const symbol = lineSymbols.find((s) => s !== "W");
            if (!symbol) {
                continue;
            }

            let matches = 0;
            for (const lineSymbol of lineSymbols) {
                if (lineSymbol === symbol || lineSymbol === Wild) {
                    matches++;
                } else {
                    break;
                }
            }

            if (matches > 0 && PayTable[symbol].length >= matches) {
                const score = PayTable[symbol][matches - 1];
                if (score > 0) {
                    paylineSymbols.push(
                        {
                            payline,
                            symbol,
                            score,
                            matches,
                        }
                    );
                }
            }
        }

        return paylineSymbols.sort((a, b) => b.score - a.score);
    }
}
