import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import { GameButtonDefinition } from "../../game.properties";
import { GameButtonLayerProps } from "./game-button-layer.props";
import { GameConfiguration } from "../../game-configuration.interface";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import { TextStyle } from "pixi.js";
import gsap from "gsap";

const TransitionDuration = 0.1;
const ClickDuration = 0.1;
const ClickScale = 0.98;

export class GameButtonLayer extends PixiJSRenderingLayer<
    GameConfiguration,
    {
        wrapper: PIXI.Container;
        caption: PIXI.Text;
    },
    string,
    GameButtonLayerProps,
    PIXI.Container
> {
    private normalSprite: PIXI.Sprite | undefined;
    private hoverSprite: PIXI.Sprite | undefined;
    private disableSprite: PIXI.Sprite | undefined;
    private activeSprite: PIXI.Sprite | undefined;
    private overlaySprite: PIXI.Sprite | undefined;
    private clickSound: Audio[] = [];
    private overlayAnimation: gsap.core.Tween | undefined;
    private clickAnimation: gsap.core.Tween | undefined;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        public readonly definition: GameButtonDefinition,
        private readonly additionalActions: {
            [key: string]: (() => (Promise<void> | void));
        } = {}
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "normal",
            },
            {
                wrapper: new PIXI.Container(),
                caption: new PIXI.Text(
                    (definition.caption ?? "").toUpperCase(),
                    {
                        fontFamily: "Montserrat",
                        ...definition,
                    }
                ),
            },
            {
                [`Button_${definition.url}`]: definition.url,
            }
        );

        this.clickSound = AudioHelper.getAudioList(definition.sound);
        this.shapes.wrapper.interactive = true;
        this.shapes.wrapper.on("mouseover", this.onMouseOver);
        this.shapes.wrapper.on("mouseout", this.onMouseOut);
        this.shapes.wrapper.on("pointertap", this.onClick);
        this.shapes.wrapper.alpha = definition.alpha ?? 1;
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);

        if (this.normalSprite) {
            const box = this.calculateLayout(
                width,
                height,
                {
                    fit: "contain",
                    maxScale: 1,
                    ...this.definition,
                    width: this.definition.width || this.normalSprite.texture.width,
                    height: this.definition.height || this.normalSprite.texture.height,
                }
            );

            this.normalSprite.scale.x = 1;
            this.normalSprite.scale.y = 1;
            this.normalSprite.x = 0;
            this.normalSprite.y = 0;

            if (this.disableSprite) {
                this.disableSprite.scale.x = 1;
                this.disableSprite.scale.y = 1;
                this.disableSprite.x = 0;
                this.disableSprite.y = 0;
            }

            if (this.hoverSprite) {
                this.hoverSprite.scale.x = 1;
                this.hoverSprite.scale.y = 1;
                this.hoverSprite.x = 0;
                this.hoverSprite.y = 0;
            }

            if (this.activeSprite) {
                this.activeSprite.scale.x = 1;
                this.activeSprite.scale.y = 1;
                this.activeSprite.x = 0;
                this.activeSprite.y = 0;
            }

            if (this.overlaySprite) {
                this.overlaySprite.scale.x = 1;
                this.overlaySprite.scale.y = 1;
                this.overlaySprite.x = (this.definition.marginX ?? 0) * box.scale;
                this.overlaySprite.y = (this.definition.marginY ?? 0) * box.scale;
            }

            const textSize = PIXI.TextMetrics.measureText(
                this.shapes.caption.text,
                this.shapes.caption.style as TextStyle
            );

            this.shapes.caption.scale.x = box.scale;
            this.shapes.caption.scale.y = box.scale;
            this.shapes.caption.x = box.x + (((textSize.width / -2) + (this.definition.marginX ?? 0)) * box.scale);
            this.shapes.caption.y = box.y + (((textSize.height / -2) + (this.definition.marginY ?? 0)) * box.scale);

            this.shapes.wrapper.scale.x = box.scale;
            this.shapes.wrapper.scale.y = box.scale;
            this.shapes.wrapper.x = box.x;
            this.shapes.wrapper.y = box.y;

            this.shapes.wrapper.hitArea = GameButtonLayer.getHitPointFromNormalized(this.definition.hitArea, 1);
        }
    }

    public play(
        loop: boolean,
        reverse: boolean
    ): void {
        if (this.overlaySprite instanceof PIXI.AnimatedSprite) {
            this.overlaySprite.loop = loop;
            this.overlaySprite.gotoAndPlay(0);
        } else if (!!this.overlaySprite) {
            this.overlayAnimation?.kill();

            if (loop) {
                this.overlayAnimation = gsap.fromTo(
                    this.overlaySprite,
                    {
                        rotation: 0,
                    },
                    {
                        rotation: Math.PI * 2,
                        duration: TransitionDuration,
                        ease: "none",
                        onComplete: () => {
                            this.overlaySprite!.rotation = 0;
                        },
                        repeat: -1,
                    }
                );
            } else {
                this.overlayAnimation = gsap.to(
                    this.overlaySprite,
                    {
                        rotation: reverse ? 0 : Math.PI,
                        duration: 0.2,
                        ease: "none",
                        onComplete: () => {
                            this.overlaySprite!.rotation = reverse ? 0 : Math.PI;
                        },
                        repeat: 0,
                    }
                );
            }
        } else if (loop) {
            // this.animation = gsap.fromTo(
            //     this.shapes.caption,
            //     {
            //         alpha: 1,
            //     },
            //     {
            //         alpha: 0.2,
            //         duration: .5,
            //         ease: "inOut",
            //         repeat: -1,
            //         yoyo: true,
            //     }
            // );
        }
    }

    protected stateChanged(props: GameButtonLayerProps): void {
        if (props.state === "busy" || props.state === "active") {
            this.play(true, false);
        }

        if (props.state === "normal" || props.state === "disabled") {
            if (this.overlaySprite instanceof PIXI.AnimatedSprite) {
                this.overlaySprite.gotoAndStop(0);
            } else if (this.overlaySprite) {
                this.overlayAnimation?.kill();
                this.overlaySprite.rotation = 0;
            } else {
                this.overlayAnimation?.kill();
                this.shapes.caption.alpha = 1;
            }
        }

        if (props.state === "disabled" || props.state === "busy") {
            if (this.hoverSprite) {
                gsap.to(
                    this.hoverSprite,
                    {
                        alpha: 0,
                        duration: TransitionDuration,
                    }
                );
            }

            if (this.disableSprite) {
                gsap.to(
                    this.shapes.caption,
                    {
                        alpha: 0.5,
                        duration: TransitionDuration,
                    }
                );

                gsap.to(
                    this.disableSprite,
                    {
                        alpha: 1,
                        duration: TransitionDuration,
                    }
                );
            } else {
                gsap.to(
                    this.shapes.wrapper,
                    {
                        alpha: 0.5,
                        duration: TransitionDuration,
                    }
                );
            }
        } else {
            if (!!this.activeSprite) {
                if (props.state === "active") {
                    gsap.to(
                        this.activeSprite,
                        {
                            alpha: 1,
                            duration: TransitionDuration,
                        }
                    );
                } else {
                    gsap.to(
                        this.activeSprite,
                        {
                            alpha: 0,
                            duration: TransitionDuration,
                        }
                    );
                }
            }

            if (this.hoverSprite) {
                gsap.to(
                    this.hoverSprite,
                    {
                        alpha: 0,
                        duration: TransitionDuration,
                    }
                );
            }

            if (this.disableSprite) {
                gsap.to(
                    this.disableSprite,
                    {
                        alpha: 0,
                        duration: TransitionDuration,
                    }
                );

                gsap.to(
                    this.shapes.caption,
                    {
                        alpha: 1,
                        duration: TransitionDuration,
                    }
                );
            } else {
                gsap.to(
                    this.shapes.wrapper,
                    {
                        alpha: 1,
                        duration: TransitionDuration,
                    }
                );
            }
        }
    }

    protected loaded(): void {
        super.loaded();

        const asset = this.assets[`Button_${this.definition.url}`];
        if (!this.normalSprite && asset?.textures?.length) {
            if (asset?.textures.length >= 1 && !!asset.textures[0].baseTexture) {
                this.normalSprite = new PIXI.Sprite(asset.textures[0]);
                this.shapes.wrapper.addChild(this.normalSprite);
            }

            if (asset?.textures.length >= 4 && !!asset.textures[3].baseTexture) {
                this.activeSprite = new PIXI.Sprite(asset.textures[3]);
                this.activeSprite.alpha = 0;
                this.shapes.wrapper.addChild(this.activeSprite);
            }

            if (asset?.textures.length >= 5 && !!asset.textures[4].baseTexture) {
                this.overlaySprite = new PIXI.Sprite(asset.textures[4]);
                this.shapes.wrapper.addChild(this.overlaySprite);
            }

            if (asset?.textures.length >= 2 && !!asset.textures[1].baseTexture) {
                this.disableSprite = new PIXI.Sprite(asset.textures[1]);
                this.disableSprite.alpha = 0;
                this.shapes.wrapper.addChild(this.disableSprite);
            }

            if (asset?.textures.length >= 3 && !!asset.textures[2].baseTexture) {
                this.hoverSprite = new PIXI.Sprite(asset.textures[2]);
                this.hoverSprite.alpha = 0;
                this.shapes.wrapper.addChild(this.hoverSprite);
            }
        }

        this.shapes.caption.updateText(false);
        this.resize(this.width, this.height);
    }

    public static getHitPointFromNormalized(hitPoint: number[], factor: number, x = 0, y = 0): PIXI.IHitArea {
        switch (hitPoint.length) {
            case 3:
                return new PIXI.Circle(x + (hitPoint[0] * factor), y + (hitPoint[1] * factor), hitPoint[2] * factor);
            case 4:
                return new PIXI.Rectangle(x + (hitPoint[0] * factor), y + (hitPoint[1] * factor), (hitPoint[2] * factor), (hitPoint[3] * factor));
            default:
                return new PIXI.Rectangle(x, y, 0, 0);
        }
    }

    private onMouseOver = () => {
        if (!this.hoverSprite || !this.normalSprite || this.props.state === "busy" || this.props.state === "disabled" || this.props.state === "active") {
            return;
        }

        gsap.to(
            this.hoverSprite,
            {
                alpha: 1,
                duration: TransitionDuration,
            }
        );

        this.play(false, false);

        // if (this.props.state !== "active") {
        //     this.play(false, false);
        // }
    };

    private onMouseOut = () => {
        if (!this.hoverSprite || !this.normalSprite || this.props.state === "busy" || this.props.state === "disabled") {
            return;
        }

        gsap.to(
            this.hoverSprite,
            {
                alpha: 0,
                duration: TransitionDuration,
            }
        );

        if (this.props.state !== "active") {
            this.play(false, true);
        }
    };

    private onClick = () => {
        if (this.props.state === "busy" || this.props.state === "disabled") {
            return;
        }

        if (!this.definition.action) {
            return;
        }

        AudioHelper.playAudio(this.clickSound);
        this.resize(this.width, this.height);
        this.clickAnimation?.kill();
        this.clickAnimation = gsap.to(
            [this.shapes.wrapper.scale, this.shapes.caption.scale],
            {
                x: this.shapes.wrapper.scale.x * ClickScale,
                y: this.shapes.wrapper.scale.y * ClickScale,
                repeat: 1,
                yoyo: true,
                duration: ClickDuration,
                onComplete: () => {
                    this.resize(this.width, this.height);
                },
            }
        );

        if (typeof this.definition.action === "function") {
            this.definition.action();
        } else if (typeof this.additionalActions[this.definition.action] !== "undefined") {
            void this.additionalActions[this.definition.action]();
        } else if (typeof this.configuration.actions[this.definition.action] !== "undefined") {
            void this.configuration.actions[this.definition.action]();
        }
    };
}
